import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import IndexDeliveryComponent from '../../../../components/Content/Delivery/Document/IndexDeliveryComponent';
import * as LastAccess from '../../../../core/action/LastAccess/index';
import * as ActionFormUnggahDocument from '../../../../core/action/Delivery/Document/ActionFormUnggahDocument';
import * as ActionFormDistribusi from '../../../../core/action/Delivery/Document/ActionFormDistribusi';
import * as ActionFormPrintWaybill from '../../../../core/action/Delivery/Document/ActionFormPrintWaybill';
import history from '../../../../core/utils/History';

interface IProps {
  actionLastAccess: any;
  actionValidateReport: any;
  actionFormUnggahDocument: any;
  actionFormDistribusi: any;
  actionFormPrintWaybill: any;
  Fiture: any;
  parsingFromKU: any;
  sideMenus: any;
}

const IndexDeliveryContainer = (props: IProps) => {
  const handleChangeTabs = (event: any) => {
    props.actionFormDistribusi.ClearAllFormDistribution();
    props.actionFormUnggahDocument.clearAllFormUnggahCsv();
    props.actionFormPrintWaybill.clearListData();

    if (event === '1') {
      props.actionLastAccess.changeLastAccessFiture('deliveryUploadData');
    } else if (event === '2') {
      props.actionLastAccess.changeLastAccessFiture('deliverySortData');
    } else if (event === '3') {
      props.actionLastAccess.changeLastAccessFiture('deliveryDistribution');
    } else if (event === '4') {
      props.actionLastAccess.changeLastAccessFiture('deliveryPrintWaybill');
    }
  };

  useEffect(() => {
    if (props.parsingFromKU !== undefined) {
      history.push('/delivery/document');
      props.actionLastAccess.changeLastAccessFiture('deliveryDistribution');
    }
  }, [props.parsingFromKU, props.actionLastAccess]);

  return (
    <IndexDeliveryComponent
      lastAccessFiture={props.Fiture}
      handleChangeTabs={handleChangeTabs}
      sideMenus={props.sideMenus}
    />
  );
};

const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  Fiture: state.LastAccess.Fiture,
  parsingFromKU: state.router.location.search.split('?')[1],
  sideMenus: state.Auth.SideMenus,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
  actionFormUnggahDocument: bindActionCreators(
    ActionFormUnggahDocument,
    dispatch
  ),
  actionFormDistribusi: bindActionCreators(ActionFormDistribusi, dispatch),
  actionFormPrintWaybill: bindActionCreators(ActionFormPrintWaybill, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IndexDeliveryContainer);
