import React, { useState } from 'react';
import SortDocumentComponent from '../Component/SortDocumentComponent';
import Button from 'components/Button';
import moment from 'moment-timezone';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getCustomerList,
  getProductListByCustomer,
} from 'services/GeneralService';
import {
  getCountingSortedDocument,
  getListSortedDocument,
  mutateScanSortDocument,
} from 'services/SortDocumentServices';
import ModalDetailSortedDocumentContainer from './ModalDetailSortedDocumentContainer';
import ModalDetailUnscanDocumentContainer from './ModalDetailUnscanDocumentContainer';
import ModalWaybillOptionContainer from './ModalWaybillOptionContainer';
import { store } from '../../../../../../core/store/ConfigStore';
import { Modal } from 'antd';

export default function SortDocumentContainer() {
  const username = store.getState().Auth.UsernameAuth;

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentSize] = useState<number>(10);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [selectedCustomerProduct, setSelectedCustomerProduct] =
    useState<any>(null);
  const [selectedTypePeriode, setSelectedTypePeriode] = useState<any>('Cycle');
  const [selectedPeriodeDelivery, setSelectedPeriodeDelivery] =
    useState<any>(null);
  const [, setSelectedTypeScan] = useState<any>('barcode');
  const [detailSortedDocument, setDetailSortedDocument] = useState<any>(null);
  const [modalDetailSortedDocument, setModalDetailSortedDocument] =
    useState<boolean>(false);
  const [modalDetailUnscanDocument, setModalDetailUnscanDocument] =
    useState<boolean>(false);
  const [modalWaybillOption, setModalWaybillOption] = useState<boolean>(false);
  const [dataWaybillOption, setDataWaybillOption] = useState<any>(null);
  const [isResponseSuccess, setIsResponseSuccess] = useState<boolean>(false);
  const [selectedWaybill, setSelectedWaybill] = useState<string | null>(null);

  const { data: dataCustomers, isFetching: isFetchingCustomers } = useQuery(
    ['getDataCustomers'],
    getCustomerList,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataProducts, isFetching: isFetchingProducts } = useQuery(
    ['dataProducts', selectedCustomer],
    getProductListByCustomer,
    {
      refetchOnWindowFocus: false,
      enabled: selectedCustomer !== null,
      onError: error => {
        console.log('error', error);
      },
    }
  );

  const {
    data: dataSortedDocument,
    isFetching: isFetchingSortedDocument,
    refetch: refetchDataSortedDocument,
  } = useQuery(
    [
      'dataSortedDocument',
      currentPage,
      currentSize,
      selectedCustomerProduct,
      selectedTypePeriode,
      selectedPeriodeDelivery,
      username,
    ],
    getListSortedDocument,
    {
      enabled:
        selectedCustomer !== null &&
        selectedCustomerProduct !== null &&
        selectedTypePeriode !== null &&
        selectedPeriodeDelivery !== null,
    }
  );
  const {
    data: dataCountingSortedDocument,
    isFetching: isFetchingCountingSortedDocument,
    refetch: refetchDataCountingSortedDocument,
  } = useQuery(
    [
      'dataCountingSortedDocument',
      selectedCustomerProduct,
      selectedTypePeriode,
      selectedPeriodeDelivery,
      username,
    ],
    getCountingSortedDocument,
    {
      enabled:
        selectedCustomer !== null &&
        selectedCustomerProduct !== null &&
        selectedTypePeriode !== null &&
        selectedPeriodeDelivery !== null,
    }
  );

  const mutateScanDocument = useMutation(mutateScanSortDocument, {
    onSuccess: (data: any, variable: any) => {
      const { isMultiple, data: waybillData } = data.data;
      if (isMultiple) {
        setModalWaybillOption(true);
        setDataWaybillOption(waybillData);
      } else {
        refetchDataSortedDocument();
        refetchDataCountingSortedDocument();
        setSelectedWaybill(variable.barcodeOrWaybill[0]);
        setModalWaybillOption(false);
        setIsResponseSuccess(true);
      }
    },
    onError: (error: any) => {
      Modal.error({
        title: <b style={{ color: '#EA1A1A' }}>Gagal menjalankan proses</b>,
        content: <p>{error.response.data?.Message}</p>,
      });
    },
  });

  const handleSubmitScanDocument = (values: any) => {
    const { typePeriode, periodeDelivery, customerProduct, typeScan, scan } =
      values;
    const payload = {
      isBarcode:
        typeScan === 'barcode' ? true : typeScan === 'waybill' ? false : false,
      barcodeOrWaybill: [scan],
      date: moment(periodeDelivery).format('YYYYMMDD'),
      filterDateType: typePeriode,
      customerProductIds: customerProduct,
      userCode: username,
    };

    mutateScanDocument.mutate(payload);
  };

  const columnData = [
    {
      title: <b>Jenis Produk</b>,
      dataIndex: 'customerProduct',
      key: 'customerProduct',
      width: 60,
    },
    {
      title: <b>Tanggal Kirim</b>,
      dataIndex: 'uploadDeliveryTime',
      key: 'uploadDeliveryTime',
      width: 60,
      render: (uploadDeliveryTime: any) => {
        return <span>{moment(uploadDeliveryTime).format('YYYY-MM-DD')}</span>;
      },
    },
    {
      title: <b>Nama File</b>,
      dataIndex: 'fileName',
      key: 'fileName',
      width: 60,
      render: (fileName: any) => {
        return <span style={{ color: '#EA1A1A' }}>{fileName}</span>;
      },
    },
    {
      title: <b>Jumlah</b>,
      render: (row: any) => {
        const { totalScan, totalWaybill } = row;
        return (
          <span style={{ color: '#00AB44' }}>
            {totalScan}/{totalWaybill}
          </span>
        );
      },
      width: 60,
    },

    {
      title: <b>Aksi</b>,
      key: 'action',
      width: 60,
      render: record => {
        return (
          <Button
            icon="eye"
            variant="info"
            onClick={() => {
              setDetailSortedDocument(record);
              setModalDetailSortedDocument(true);
            }}
          />
        );
      },
    },
  ];

  const handleRefresh = () => {
    setIsResponseSuccess(false);
    refetchDataSortedDocument();
    refetchDataCountingSortedDocument();
  };
  const listCustomer =
    dataCustomers &&
    dataCustomers?.length > 0 &&
    dataCustomers?.map((item: any) => ({
      id: item.id,
      name: item.name,
    }));
  const listProduct =
    dataProducts &&
    dataProducts?.length > 0 &&
    dataProducts?.map((item: any) => ({
      id: item.id,
      name: item.name,
    }));
  return (
    <>
      <SortDocumentComponent
        columnData={columnData}
        currentPage={currentPage}
        dataCustomers={dataCustomers}
        dataProducts={dataProducts}
        dataSortedDocument={dataSortedDocument?.data || []}
        handleSubmitScanDocument={handleSubmitScanDocument}
        isFetchingCountingSortedDocument={isFetchingCountingSortedDocument}
        isFetchingCustomers={isFetchingCustomers}
        isFetchingProducts={isFetchingProducts}
        isFetchingSortedDocument={isFetchingSortedDocument}
        isLoadingMutation={mutateScanDocument.isLoading}
        isResponseSuccess={isResponseSuccess}
        listCustomer={listCustomer || []}
        listProduct={listProduct || []}
        handleRefresh={handleRefresh}
        selectedWaybill={selectedWaybill}
        setCurrentPage={setCurrentPage}
        setModalDetailUnscanDocument={setModalDetailUnscanDocument}
        setSelectedCustomer={setSelectedCustomer}
        setSelectedCustomerProduct={setSelectedCustomerProduct}
        setSelectedPeriodeDelivery={setSelectedPeriodeDelivery}
        setSelectedTypePeriode={setSelectedTypePeriode}
        setSelectedTypeScan={setSelectedTypeScan}
        totalDatas={dataSortedDocument?.totalDatas || 0}
        totalPages={dataSortedDocument?.totalPages || 0}
        totalUnscan={dataCountingSortedDocument?.totalUnscan || 0}
        totalUserScan={dataCountingSortedDocument?.totalUserScan || 0}
        totalWaybill={dataCountingSortedDocument?.totalWaybill || 0}
      />
      <ModalDetailSortedDocumentContainer
        modalDetailSortedDocument={modalDetailSortedDocument}
        setModalDetailSortedDocument={setModalDetailSortedDocument}
        detailSortedDocument={detailSortedDocument}
        setDetailSortedDocument={setDetailSortedDocument}
        refetchDataSortedDocument={refetchDataSortedDocument}
        refetchDataCountingSortedDocument={refetchDataCountingSortedDocument}
      />
      <ModalDetailUnscanDocumentContainer
        modalDetailUnscanDocument={modalDetailUnscanDocument}
        setModalDetailUnscanDocument={setModalDetailUnscanDocument}
        selectedCustomer={selectedCustomer}
        selectedCustomerProduct={selectedCustomerProduct}
        selectedTypePeriode={selectedTypePeriode}
        selectedPeriodeDelivery={selectedPeriodeDelivery}
      />
      <ModalWaybillOptionContainer
        modalWaybillOption={modalWaybillOption}
        setModalWaybillOption={setModalWaybillOption}
        dataWaybillOption={dataWaybillOption}
        setDataWaybillOption={setDataWaybillOption}
        selectedWaybill={selectedWaybill}
        setSelectedWaybill={setSelectedWaybill}
        selectedCustomerProduct={selectedCustomerProduct}
        selectedTypePeriode={selectedTypePeriode}
        selectedPeriodeDelivery={selectedPeriodeDelivery}
        handleSubmitScanDocument={handleSubmitScanDocument}
        isLoadingMutation={mutateScanDocument.isLoading}
      />
    </>
  );
}
