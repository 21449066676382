import React, { Component } from 'react';
import { Modal } from 'antd';

interface IProps {
  content?: any;
  children?: React.ReactNode;
  width: any;
  style?: any;
  teksTitle: any;
  visible: any;
  onCancel?: any;
  className?: any;
  bodyStyle?: any;
  footer?: any;
}

export default class ModalMaterial extends Component<IProps> {
  render() {
    return (
      <div>
        <Modal
          centered
          className={this.props.className}
          width={this.props.width}
          style={this.props.style}
          title={
            <div style={{ color: '#11BEFF' }}>
              <b>{this.props.teksTitle}</b>
            </div>
          }
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          maskClosable={false}
          footer={this.props.footer || null}
          bodyStyle={this.props.bodyStyle}
        >
          {this.props.children}
          {this.props.content}
        </Modal>
      </div>
    );
  }
}
