import { ref, remove, update } from 'firebase/database';
import moment from 'moment';
import { getDb } from 'module/Config/firebaseConfig';

export const getNameFileCountingManifest = (e: any) => {
  switch (e) {
    case 'Scan':
      return 'Manifest yang sudah di Scan';
    case 'UnScan':
      return 'Manifest yang belum di Scan';
    case 'Total':
      return 'Manifest total';
    case 'ScanUser':
      return 'Manifest yang sudah di scan user';
  }
  return null;
};

const reduceMenusFormat = (menus, dataAllMenus = [] as any) => {
  const menusReduce = [] as any;

  dataAllMenus.forEach(dataMenu => {
    const subMenus = [] as any;

    if (dataMenu.subMenus.length > 0) {
      dataMenu.subMenus.forEach(subMenu => {
        if (menus.includes(subMenu.key)) {
          subMenus.push({
            id: subMenu.id,
            key: subMenu.key,
            permission: { view: true },
          });
        } else {
          subMenus.push({
            id: subMenu.id,
            key: subMenu.key,
            permission: { view: false },
          });
        }
      });
    }

    if (menus.includes(dataMenu.key)) {
      menusReduce.push({
        menu: { id: dataMenu.id },
        key: dataMenu.key,
        permission: { view: true },
        subMenu: subMenus,
      });
    } else {
      menusReduce.push({
        menu: { id: dataMenu.id },
        key: dataMenu.key,
        permission: { view: false },
        subMenu: subMenus,
      });
    }
  });

  return menusReduce;
};

const reduceRoleFormat = (dataMenus: Array<any>) => {
  const menus = [] as any;

  dataMenus.forEach(item => {
    const subMenus = [] as any;
    const tabMenus = [] as any;
    if (item?.subMenus?.length > 0) {
      item.subMenus.forEach(subMenu => {
        const tabMenusChild = [] as any;
        if (item.tabMenu && subMenu?.tabMenus?.length > 0) {
          subMenu.tabMenus.forEach(tabmenu => {
            tabMenusChild.push({
              id: tabmenu.id,
              key: tabmenu.key,
              permission: tabmenu.permission,
            });
          });
        }

        subMenus.push({
          id: subMenu.id,
          key: subMenu.key,
          permission: subMenu.permission,
          tabMenu: tabMenusChild,
        });
      });
    }
    if (item?.tabMenu && item?.tabMenus?.length > 0) {
      item.tabMenus.forEach(tabMenu => {
        tabMenus.push({
          id: tabMenu.id,
          key: tabMenu.key,
          permission: tabMenu.permission,
        });
      });
    }
    menus.push({
      menu: { id: item.id },
      key: item.key,
      subMenu: subMenus,
      tabMenu: tabMenus,
      permission: item.permission,
    });
  });

  return menus;
};

const getParams = params => {
  var url = new URL(window.location.href);
  return url.searchParams.get(params);
};

const getQueryParamString = (url: string, paramName: string): string | null => {
  const searchParams = new URLSearchParams(url.split('?')[1]);
  return searchParams.get(paramName);
};

const downloadBlob = (data: any, fileName, extension = 'pdf') => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  const file_name = `${fileName}.${extension}`;
  link.href = url;
  link.setAttribute('download', file_name);
  document.body.appendChild(link);
  link.click();
};

const getBase64 = (file: any) => {
  return new Promise(resolve => {
    let baseURL;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

const removeNotificationMobile = waybil => {
  const removeTarget = ref(
    getDb,
    `${process.env.REACT_APP_FIREBASE_PARENT_MOBILE}/${waybil}`
  );
  return remove(removeTarget);
};

const addNotificationMobile = (waybil, getUsername) => {
  const urlTarget =
    (ref(getDb), `${process.env.REACT_APP_FIREBASE_PARENT_MOBILE}/${waybil}`);
  const postData = {
    actor:
      getUsername + `(${moment(new Date()).format('YYYY-MM-DD HH:mm:ss')})`,
  };

  const updates: any = {};
  updates[urlTarget] = postData;
  return update(ref(getDb), updates);
};

const trimText = value => {
  if (value.startsWith(' ')) {
    value = value.trim();
  }

  return value;
};

function getObjectKey(obj: any, value: string) {
  return Object.keys(obj).find(key => obj[key] === value);
}

export const getTabMenus = (data = [], menus, submenus?: string) => {
  const selectedMenus =
    data.find((value: any) => value.label === menus) || (null as any);
  if (submenus) {
    if (selectedMenus.subMenus.length > 0) {
      return (
        selectedMenus?.subMenus?.find(value => value.label === submenus)
          .tabMenus || []
      );
    }
    return [];
  }

  return selectedMenus?.tabMenus || [];
};

const openNewTab = (url: string) => {
  window.open(url, '_blank');
};

const fileReader = (
  files: File | null
): Promise<{
  fileDocument: File | null;
  filePreview: string;
  fileName: string | undefined;
}> => {
  let filePreview: string = '';
  let fileDocument: File | null = files;
  let fileName: string | undefined = files?.name;

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = event => {
      reject(new Error('File reading error'));
    };

    reader.onload = async () => {
      if (reader.readyState === FileReader.DONE) {
        if (typeof reader.result === 'string') {
          filePreview = reader.result.split(',')[1];
          resolve({
            fileDocument,
            filePreview,
            fileName,
          });
        } else {
          reject(new Error('Invalid file result type'));
        }
      }
    };

    if (files) {
      reader.readAsDataURL(files);
    } else {
      reject(new Error('No file provided'));
    }
  });
};

const processProgressRequest =
  (setLoadingProgress: any) => (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setLoadingProgress(percentCompleted);
  };

const formatDateToUtc = (date, format = 'DD/MM/YYYY') => {
  return moment(date).utc().format(format);
};

const formatDate = (date, format = 'DD/MM/YYYY') => {
  return moment(date).format(format);
};

const mapProductType = (productType: string) => {
  switch (productType) {
    case 'Card':
      return 'Kartu';
    case 'Policy':
      return 'Polis';
    case 'Letter':
      return 'Surat';
    case 'Others':
      return 'Lainnya';
    default:
      return null;
  }
};

export {
  reduceMenusFormat,
  reduceRoleFormat,
  getParams,
  downloadBlob,
  getBase64,
  removeNotificationMobile,
  addNotificationMobile,
  trimText,
  getObjectKey,
  openNewTab,
  getQueryParamString,
  fileReader,
  processProgressRequest,
  formatDateToUtc,
  formatDate,
  mapProductType,
};
