import React, { useEffect, useState } from 'react';
import FormPrintWaybillContainer from '../../../../containers/Content/Delivery/Document/FormPrintWaybillContainer';
import Distribution from 'module/DeliveryDocument/Distribution/container';
import FormUnggahDataContainer from '../../../../containers/Content/Delivery/Document/UploadData/FormUnggahDocumentContainer';
import { Tabs } from 'antd';
import SortDocumentContainer from 'containers/Content/Delivery/Document/SortDocument/Container/SortDocumentContainer';
import { getTabMenus } from 'lib/util';

const { TabPane } = Tabs;
interface IProps {
  lastAccessFiture: any;
  handleChangeTabs: any;
  sideMenus: any;
}

const IndexDeliveryComponent = ({
  lastAccessFiture,
  handleChangeTabs,
  sideMenus,
}: IProps) => {
  const [activeKey, setActiveKey] = useState('1');
  const tabMenus = getTabMenus(sideMenus, 'Pengiriman', 'Dokumen') || [];

  useEffect(() => {
    if (lastAccessFiture === 'deliveryPrintWaybill') {
      setActiveKey('4');
    } else if (lastAccessFiture === 'deliveryDistribution') {
      setActiveKey('3');
    } else if (lastAccessFiture === 'deliverySortData') {
      setActiveKey('2');
    } else if (lastAccessFiture === 'deliveryUploadData') {
      setActiveKey('1');
    }
  }, [lastAccessFiture]);

  useEffect(() => {
    const handleChange = () => {
      if (lastAccessFiture === 'deliveryUploadData') {
        handleChangeTabs('1');
      } else if (lastAccessFiture === 'deliverySortData') {
        handleChangeTabs('2');
      } else if (lastAccessFiture === 'deliveryDistribution') {
        handleChangeTabs('3');
      } else if (lastAccessFiture === 'deliveryPrintWaybill') {
        handleChangeTabs('4');
      }
    };
    if (lastAccessFiture !== null) {
      handleChange();
    }
  }, [lastAccessFiture, handleChangeTabs]);

  return (
    <div>
      <Tabs
        style={{ padding: '35px' }}
        defaultActiveKey={activeKey}
        onChange={handleChangeTabs}
        activeKey={activeKey}
      >
        {tabMenus.map((item, index) => (
          <TabPane tab={<b>{item.label}</b>} key={(index + 1).toString()}>
            {item.label === 'Unggah Data' ? (
              <FormUnggahDataContainer />
            ) : item.label === 'Sortir Dokumen' ? (
              <SortDocumentContainer />
            ) : item.label === 'Distribusi' ? (
              <Distribution />
            ) : item.label === 'Cetak Waybill' ? (
              <FormPrintWaybillContainer />
            ) : null}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default IndexDeliveryComponent;
