import { Action } from 'redux';

const initialState = {
  PropertiesCustomer: {
    IdStatus: null,
    IdCustomer: null,
    EditCustomer: null,
    ReasonDeliveryStatus: null,
    Radiobutton: null,
    ChangeType: null,
    LoadingCustomer: false,
    TypeLoadingCustomer: null,
    LoadingCustomerReportFormat: false,
    LoadingStatus: false,
    TypeLoadingStatus: null,
    CsvTemplate: null,
  },
  DetailCustomer: {
    CustomerName: null,
    CustomerCode: null,
    CustomerType: null,
    CustomerTypeOther: null,
    CustomerTelephone: null,
    CustomerEmail: null,
    CustomerAccess: [],
    CustomerAddress: null,
  },
  CustomerAccount: {
    IdCustomerAccount: null,
    FirstName: null,
    LastName: null,
    PhoneNumber: null,
    Email: null,
    Username: null,
    Address: null,
    StatusAccount: true,
    CustomerProductId: [],
    CustomerListAccount: [],
  },
  FormCustomCustomerStatus: {
    StatusPosition: null,
    StatusCode: null,
    StatusDescription: null,
    StatusLabel: null,
    StatusAttempt: false,
    StatusFreeText: false,
    StatusYBS: false,
  },
  CustomerFiture: {
    TypeFiture: null,
    IdFiture: null,
    SmsDelivery: false,
    SmsDelivered: false,
    Call: false,
    SenderAlias: null,
    SmsDeliveryBody: null,
    SmsDeliveredBody: null,
    IsRadius: true,
    IsRequestPhone: false,
    IsHistory: true,
    RadiusMeters: 1000,
    isMonthFilter: false,
    searchMonthFilter: null,
  },
  FormCustomCsvCustomer: {
    IdCsv: null,
    FileSettingName: null,
    StatusDefault: false,
    ReverseBarcode: false,
    CustomerNameCsv: null,
    DelimitedCsv: null,
    FileType: null,
    CycleCsv: null,
    FormatCycleCsv: 'dd/MM/yyyy',
    CityCsv: null,
    AddressCsv: null,
    AddressOffice: null,
    ListAddressCsv: [],
    ListAddressOffice: [],
    BarcodeCsv: null,
    OwnerNameCsv: null,
    PostalCodeCsv: null,
    NoTelpCsv: null,
    CallLinkCsv: null,
    AdditionalDataCsv: null,
    ListAdditionalDataCsv: [],
    TypeFormCsv: null,
    GroupNameCsv: null,
    SearchTypeCsv: null,
    SearchKeyCsv: null,
    CheckboxCallCenter: false,
    Waybill: null,
    AddressUpdate: null,
  },
  FormCustomerConfigReport: {
    TypeFormConfigReport: null,
    IdCustomerReport: null,
    CustomerNameReport: null,
    FormatReport: null,
    PeriodeReport: null,
    DelimitedReport: null,
    WithoutHeader: false,
    ShowInPortal: false,
    SettingReport: null,
    SettingReportName: null,
    CustomDataReport: {
      TableCustomReportDataCustomer: [],
    },
    FieldCustomReportData: {
      HeaderCustomReportCustomer: null,
      SelectValueCustomReportCustomer: false,
      ValueCustomReportCustomer: null,
      SourceCustomReportCustomer: null,
      ColumnCustomReportCustomer: null,
      ListColumnCustomReportCustomer: [],
      CheckBoxSubColumnCustomReportCustomer: false,
      SubColumnCustomReportCustomer: null,
      ListSubColumnCustomReportCustomer: [],
      FormatValueCustomReportCustomer: null,
      FormatColumnCustomReportCustomer: null,
      PositionCustomReportCustomer: null,
      IdCustomReportCustomer: null,
    },
    CustomDataReportCustomer: {
      IdCustomReportCustomer: null,
      Header: null,
      Position: null,
      Format: null,
      Value: false,
      ValueDefault: null,
      SourceTable: null,
      FormatType: null,
      HistoryType: null,
      FormatColumn: null,
      CharacterLength: null,
      SourceColumn: null,
      SourceColumnSec: null,
      ListSourceColumn: [],
      ListSourceColumnSec: [],
      DeliveryTo: null,
      SubColumn: null,
      SubColumnSec: null,
      ListSubColumn: [],
      ListSubColumnSec: [],
      ReplaceSuccess: null,
      ReplaceReturn: null,
      ReplaceOnProcess: null,
      ReplaceOnHold: null,
      CheckBoxPetik: false,
      CheckBoxOthers: false,
    },
  },
  ListCustomerCsvType: [],
  ListCustomerReport: [],
  ListCustomer: [],
  ListCustomCsvCustomer: [],
  DefaultDataStatus: [],
  CustomDataStatus: [],
};

export interface IAuthAction extends Action<string> {}

export interface IChangeIdStatus extends IAuthAction {
  IdStatus: any;
}
export interface IChangeIdCustomer extends IAuthAction {
  idcustomer: any;
}
export interface IChangeReasonStat extends IAuthAction {
  ReasonDeliveryStatus: any;
}
export interface IChangeRadiobutton extends IAuthAction {
  Radiobutton: any;
}
export interface ISetCsvTemplate extends IAuthAction {
  CsvTemplate: any;
}
export interface IChangeCType extends IAuthAction {
  ChangeType: any;
}
export interface ISetTypeLoadingCustomer extends IAuthAction {
  TypeLoadingCustomer: any;
}

export interface ISetTypeLoadingStatus extends IAuthAction {
  TypeLoadingStatus: any;
}
export interface IChangeEditCustomer extends IAuthAction {
  editcustomer: any;
}
export interface IChangeCustomeName extends IAuthAction {
  customername: any;
}

export interface IChangeCustomerCode extends IAuthAction {
  customercode: any;
}
export interface IChangeCustomerType extends IAuthAction {
  customertype: any;
}
export interface IChangeCustomerTypeOther extends IAuthAction {
  customertypeother: any;
}

export interface IChangeCustomerTelephone extends IAuthAction {
  customertelephone: any;
}
export interface IChangeCustomerEmail extends IAuthAction {
  customeremail: any;
}
export interface IChangeCustomerAccess extends IAuthAction {
  customeraccess: any;
}
export interface IChangeCustomerAddress extends IAuthAction {
  customeraddress: any;
}
export interface IChangeStatusPosition extends IAuthAction {
  StatusPosition: any;
}
export interface IChangeStatusAttempt extends IAuthAction {
  StatusAttempt: any;
}
export interface IChangeStatusFreeText extends IAuthAction {
  StatusFreeText: any;
}
export interface IChangeStatusYBS extends IAuthAction {
  StatusYBS: any;
}
export interface IChangeStatusCode extends IAuthAction {
  StatusCode: any;
}
export interface IChangeStatusDescription extends IAuthAction {
  StatusDescription: any;
}
export interface IChangeStatusLabel extends IAuthAction {
  StatusLabel: any;
}
export interface IChangeTypeFiture extends IAuthAction {
  TypeFiture: any;
}
export interface IChangeIdFiture extends IAuthAction {
  IdFiture: any;
}
export interface IChangeSmsDelivery extends IAuthAction {
  SmsDelivery: any;
}
export interface IChangeSmsDelivered extends IAuthAction {
  SmsDelivered: any;
}
export interface IChangeCall extends IAuthAction {
  Call: any;
}
export interface IChangeIsRadius extends IAuthAction {
  IsRadius: any;
}
export interface IChangeIsRequestPhone extends IAuthAction {
  IsRequestPhone: any;
}
export interface IChangeIsHistory extends IAuthAction {
  IsHistory: any;
}
export interface IChangeRadiusMeters extends IAuthAction {
  RadiusMeters: any;
}
export interface IChangeSenderAlias extends IAuthAction {
  SenderAlias: any;
}
export interface IChangeSmsDeliveryBody extends IAuthAction {
  SmsDeliveryBody: any;
}
export interface IChangeSmsDeliveredBody extends IAuthAction {
  SmsDeliveredBody: any;
}
export interface ISetListCustomer extends IAuthAction {
  listCustomer: any;
}
export interface ISetListCustomerReport extends IAuthAction {
  ListCustomerReport: any;
}
export interface IChangeIdCustomerAccount extends IAuthAction {
  IdCustomerAccount: any;
}
export interface IChangeFirstNameAccount extends IAuthAction {
  FirstName: any;
}
export interface IChangeLastNameAccount extends IAuthAction {
  LastName: any;
}
export interface IChangePhoneNumberAccount extends IAuthAction {
  PhoneNumber: any;
}
export interface IChangeEmailAccount extends IAuthAction {
  Email: any;
}
export interface IChangeUsernameAccount extends IAuthAction {
  Username: any;
}
export interface IChangeAddressAccount extends IAuthAction {
  Address: any;
}
export interface IChangeStatusAccount extends IAuthAction {
  StatusAccount: any;
}
export interface ISetCustomerProductId extends IAuthAction {
  CustomerProductId: any;
}
export interface ISetCustomerListAccount extends IAuthAction {
  CustomerListAccount: any;
}
export interface ISetListCustomerCsvType extends IAuthAction {
  ListCustomerCsvType: any;
}
export interface ISetListCustomCsvCustomer extends IAuthAction {
  ListCustomCsvCustomer: any;
}
export interface ISetDefaultStatus extends IAuthAction {
  DefaultDataStatus: any;
}
export interface ISetCustomStatus extends IAuthAction {
  CustomDataStatus: any;
}
export interface ISetIdCsv extends IAuthAction {
  IdCsv: any;
}
export interface ISetCustomerNameCsv extends IAuthAction {
  CustomerNameCsv: any;
}
export interface ISetFileSettingNameCsv extends IAuthAction {
  FileSettingName: any;
}
export interface ISetStatusDefaultCsv extends IAuthAction {
  StatusDefault: any;
}
export interface ISetReverseBarcodeCsv extends IAuthAction {
  ReverseBarcode: any;
}
export interface ISetDelimitedCsv extends IAuthAction {
  DelimitedCsv: any;
}
export interface ISetFileType extends IAuthAction {
  FileType: any;
}
export interface ISetCycleCsv extends IAuthAction {
  CycleCsv: any;
}

export interface ISetFormatCycleCsv extends IAuthAction {
  FormatCycleCsv: any;
}
export interface ISetCityCsv extends IAuthAction {
  CityCsv: any;
}
export interface ISetAddressCsv extends IAuthAction {
  AddressCsv: any;
}
export interface ISetAddressOffice extends IAuthAction {
  AddressOffice: any;
}
export interface ISetListAddressCsv extends IAuthAction {
  ListAddressCsv: any;
}
export interface ISetListAddressOffice extends IAuthAction {
  ListAddressOffice: any;
}
export interface ISetBarcodeCsv extends IAuthAction {
  BarcodeCsv: any;
}
export interface ISetOwnerNameCsv extends IAuthAction {
  OwnerNameCsv: any;
}
export interface ISetPostalCodeCsv extends IAuthAction {
  PostalCodeCsv: any;
}
export interface ISetNoTelpCsv extends IAuthAction {
  NoTelpCsv: any;
}
export interface ISetCallLinkCsv extends IAuthAction {
  CallLinkCsv: any;
}
export interface ISetAdditionalDataCsv extends IAuthAction {
  AdditionalDataCsv: any;
}
export interface ISetListAdditionalDataCsv extends IAuthAction {
  ListAdditionalDataCsv: any;
}
export interface ISetTypeFormCsv extends IAuthAction {
  TypeFormCsv: any;
}
export interface ISetGroupNameCsv extends IAuthAction {
  GroupNameCsv: any;
}
export interface ISetSearchTypeCsv extends IAuthAction {
  SearchTypeCsv: any;
}
export interface ISetSearchKeyCsv extends IAuthAction {
  SearchKeyCsv: any;
}
export interface ISetCheckboxCallCenterCsv extends IAuthAction {
  CheckboxCallCenter: any;
}
export interface ISetWaybillCsv extends IAuthAction {
  Waybill: any;
}
export interface ISetAddressUpdateCsv extends IAuthAction {
  AddressUpdate: any;
}
export interface IChangeTypeFormConfigReport extends IAuthAction {
  TypeFormConfigReport: any;
}
export interface IChangeIdCustomerReport extends IAuthAction {
  IdCustomerReport: any;
}
export interface IChangeCustomerNameReport extends IAuthAction {
  CustomerNameReport: any;
}
export interface IChangeFormatReport extends IAuthAction {
  FormatReport: any;
}
export interface IChangePeriodeReport extends IAuthAction {
  PeriodeReport: any;
}
export interface IChangeDelimitedReport extends IAuthAction {
  DelimitedReport: any;
}
export interface IChangeWithoutHeaderReport extends IAuthAction {
  WithoutHeader: any;
}
export interface IChangeShowInPortalReport extends IAuthAction {
  ShowInPortal: any;
}
export interface IChangeSettingReport extends IAuthAction {
  SettingReport: any;
}
export interface IChangeSettingReportName extends IAuthAction {
  SettingReportName: any;
}
export interface IChangeCharacterLength extends IAuthAction {
  CharacterLength: any;
}
export interface ISetTableCustomReportDataCustomer extends IAuthAction {
  TableCustomReportDataCustomer: any;
}
export interface ISetHeaderCustomReportCustomer extends IAuthAction {
  HeaderCustomReportCustomer: any;
}
export interface ISetCheckBoxValueCustomReportCustomer extends IAuthAction {
  CheckBoxValueCustomReportCustomer: any;
}
export interface ISetValueCustomReportCustomer extends IAuthAction {
  ValueCustomReportCustomer: any;
}
export interface ISetSourceCustomReportCustomer extends IAuthAction {
  SourceCustomReportCustomer: any;
}
export interface ISetColumnCustomReportCustomer extends IAuthAction {
  ColumnCustomReportCustomer: any;
}
export interface ISetListColumnCustomReportCustomer extends IAuthAction {
  ListColumnCustomReportCustomer: any;
}
export interface ISetSubColumnCustomReportCustomer extends IAuthAction {
  SubColumnCustomReportCustomer: any;
}
export interface ISetListSubColumnCustomReportCustomer extends IAuthAction {
  ListSubColumnCustomReportCustomer: any;
}
export interface ISetFormatValueCustomReportCustomer extends IAuthAction {
  FormatValueCustomReportCustomer: any;
}
export interface ISetFormatColumnCustomReportCustomer extends IAuthAction {
  FormatColumnCustomReportCustomer: any;
}
export interface ISetPositionCustomReportCustomer extends IAuthAction {
  PositionCustomReportCustomer: any;
}
export interface ISetIdCustomReportCustomer extends IAuthAction {
  IdCustomReportCustomer: any;
}
export interface ISetCdrcIdCustomReportCustomer extends IAuthAction {
  IdCustomReportCustomer: any;
}
export interface ISetCdrcHeader extends IAuthAction {
  Header: any;
}
export interface ISetCdrcPosition extends IAuthAction {
  Position: any;
}
export interface ISetCdrcFormat extends IAuthAction {
  Format: any;
}
export interface ISetCdrcCheckBoxPetik extends IAuthAction {
  CheckBoxPetik: any;
}
export interface ISetCdrcCheckBoxOthers extends IAuthAction {
  CheckBoxOthers: any;
}
export interface ISetCdrcValue extends IAuthAction {
  Value: any;
}
export interface ISetCdrcValueDefault extends IAuthAction {
  ValueDefault: any;
}
export interface ISetCdrcSourceTable extends IAuthAction {
  SourceTable: any;
}
export interface ISetCdrcFormatType extends IAuthAction {
  FormatType: any;
}
export interface ISetCdrcHistoryType extends IAuthAction {
  HistoryType: any;
}
export interface ISetCdrcFormatColumn extends IAuthAction {
  FormatColumn: any;
}
export interface ISetCdrcSourceColumn extends IAuthAction {
  SourceColumn: any;
}
export interface ISetCdrcSourceColumnSec extends IAuthAction {
  SourceColumnSec: any;
}
export interface ISetCdrcDeliveryTo extends IAuthAction {
  DeliveryTo: any;
}
export interface ISetCdrcSubColumn extends IAuthAction {
  SubColumn: any;
}
export interface ISetCdrcSubColumnSec extends IAuthAction {
  SubColumnSec: any;
}
export interface ISetCdrcReplaceSuccess extends IAuthAction {
  ReplaceSuccess: any;
}
export interface ISetCdrcReplaceReturn extends IAuthAction {
  ReplaceReturn: any;
}
export interface ISetCdrcReplaceOnProcess extends IAuthAction {
  ReplaceOnProcess: any;
}
export interface ISetCdrcReplaceOnHold extends IAuthAction {
  ReplaceOnHold: any;
}
export interface ISetCdrcListSourceColumn extends IAuthAction {
  ListSourceColumn: any;
}
export interface ISetCdrcListSourceColumnSec extends IAuthAction {
  ListSourceColumnSec: any;
}
export interface ISetCdrcListSubColumn extends IAuthAction {
  ListSubColumn: any;
}
export interface ISetCdrcListSubColumnSec extends IAuthAction {
  ListSubColumnSec: any;
}
export interface ISetCdrcValueValue extends IAuthAction {
  Value: any;
}
export interface IChangeIsMonthFilter extends IAuthAction {
  isMonthFilter: any;
}
export interface IChangeSearchMonthFilter extends IAuthAction {
  searchMonthFilter: any;
}
export default function AuthReducerDetailId(
  state = initialState,
  action: IAuthAction
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_ID_STATUS':
      const changeIdStatus = action as IChangeIdStatus;
      newState.PropertiesCustomer.IdStatus = changeIdStatus.IdStatus;

      return { ...newState };
    case 'CHANGE_CUSTOMER_ID_DC':
      const changeIdCustomer = action as IChangeIdCustomer;
      newState.PropertiesCustomer.IdCustomer = changeIdCustomer.idcustomer;

      return { ...newState };

    case 'CHANGE_REASON_STAT':
      const changeReasonStat = action as IChangeReasonStat;
      newState.PropertiesCustomer.ReasonDeliveryStatus =
        changeReasonStat.ReasonDeliveryStatus;

      return { ...newState };

    case 'CHANGE_RADIOBUTTON':
      const changeRadiobutton = action as IChangeRadiobutton;
      newState.PropertiesCustomer.Radiobutton = changeRadiobutton.Radiobutton;

      return { ...newState };

    case 'CHANGE_CTYPE':
      const changeCType = action as IChangeCType;
      newState.PropertiesCustomer.ChangeType = changeCType.ChangeType;

      return { ...newState };

    case 'SET_CSV_TEMPLATE':
      const setCsvTemplate = action as ISetCsvTemplate;
      newState.PropertiesCustomer.CsvTemplate = setCsvTemplate.CsvTemplate;

      return { ...newState };

    case 'SET_LOADING_CUSTOMER':
      newState.PropertiesCustomer.LoadingCustomer =
        !newState.PropertiesCustomer.LoadingCustomer;

      return { ...newState };
    case 'SET_TYPE_LOADING_CUSTOMER':
      const setTypeLoadingCustomer = action as ISetTypeLoadingCustomer;
      newState.PropertiesCustomer.TypeLoadingCustomer =
        setTypeLoadingCustomer.TypeLoadingCustomer;

      return { ...newState };
    case 'SET_LOADING_CUSTOMER_REPORT_FORMAT':
      newState.PropertiesCustomer.LoadingCustomerReportFormat =
        !newState.PropertiesCustomer.LoadingCustomerReportFormat;

      return { ...newState };

    case 'SET_LOADING_STATUS':
      newState.PropertiesCustomer.LoadingStatus =
        !newState.PropertiesCustomer.LoadingStatus;

      return { ...newState };

    case 'SET_TYPE_LOADING_STATUS':
      const setTypeLoadingStatus = action as ISetTypeLoadingStatus;
      newState.PropertiesCustomer.TypeLoadingStatus =
        setTypeLoadingStatus.TypeLoadingStatus;

      return { ...newState };

    case 'CHANGE_EDIT_CUSTOMER':
      const changeEditCustomer = action as IChangeEditCustomer;
      newState.PropertiesCustomer.EditCustomer =
        changeEditCustomer.editcustomer;

      return { ...newState };

    case 'CHANGE_NAME_CUSTOMER':
      const changeCustomerName = action as IChangeCustomeName;
      newState.DetailCustomer.CustomerName = changeCustomerName.customername;

      return { ...newState };

    case 'CHANGE_CUSTOMER_CODE':
      const changeCustomerCode = action as IChangeCustomerCode;
      newState.DetailCustomer.CustomerCode = changeCustomerCode.customercode;

      return { ...newState };

    case 'CHANGE_CUSTOMER_TYPE':
      const changeCustomerType = action as IChangeCustomerType;
      newState.DetailCustomer.CustomerType = changeCustomerType.customertype;

      return { ...newState };
    case 'CHANGE_CUSTOMER_TYPE_OTHER':
      const changeCustomerTypeOther = action as IChangeCustomerTypeOther;
      newState.DetailCustomer.CustomerTypeOther =
        changeCustomerTypeOther.customertypeother;

      return { ...newState };

    case 'CHANGE_CUSTOMER_TELEPHONE':
      const changeCustomerTelephone = action as IChangeCustomerTelephone;
      newState.DetailCustomer.CustomerTelephone =
        changeCustomerTelephone.customertelephone;

      return { ...newState };

    case 'CHANGE_CUSTOMER_EMAIL':
      const changeCustomerEmail = action as IChangeCustomerEmail;
      newState.DetailCustomer.CustomerEmail = changeCustomerEmail.customeremail;

      return { ...newState };

    case 'CHANGE_CUSTOMER_ACCESS':
      const changeCustomerAccess = action as IChangeCustomerAccess;
      newState.DetailCustomer.CustomerAccess =
        changeCustomerAccess.customeraccess;

      return { ...newState };

    case 'CHANGE_CUSTOMER_ADDRESS':
      const changeCustomerAddress = action as IChangeCustomerAddress;
      newState.DetailCustomer.CustomerAddress =
        changeCustomerAddress.customeraddress;

      return { ...newState };

    case 'CHANGE_STATUS_CUSTOMER_POSITION':
      const changeStatusPosition = action as IChangeStatusPosition;
      newState.FormCustomCustomerStatus.StatusPosition =
        changeStatusPosition.StatusPosition;

      return { ...newState };

    case 'CHANGE_STATUS_CUSTOMER_CODE':
      const changeStatusCode = action as IChangeStatusCode;
      newState.FormCustomCustomerStatus.StatusCode =
        changeStatusCode.StatusCode;

      return { ...newState };

    case 'CHANGE_STATUS_CUSTOMER_DESCRIPTION':
      const changeStatusDescription = action as IChangeStatusDescription;
      newState.FormCustomCustomerStatus.StatusDescription =
        changeStatusDescription.StatusDescription;

      return { ...newState };

    case 'CHANGE_STATUS_CUSTOMER_LABEL':
      const changeStatusLabel = action as IChangeStatusLabel;
      newState.FormCustomCustomerStatus.StatusLabel =
        changeStatusLabel.StatusLabel;

      return { ...newState };

    case 'CHANGE_STATUS_CUSTOMER_ATTEMPT':
      const changeStatusAttempt = action as IChangeStatusAttempt;
      newState.FormCustomCustomerStatus.StatusAttempt =
        changeStatusAttempt.StatusAttempt;

      return { ...newState };

    case 'CHANGE_STATUS_CUSTOMER_FREE_TEXT':
      const changeStatusFreeText = action as IChangeStatusFreeText;
      newState.FormCustomCustomerStatus.StatusFreeText =
        changeStatusFreeText.StatusFreeText;

      return { ...newState };

    case 'CHANGE_STATUS_CUSTOMER_YBS':
      const changeStatusYBS = action as IChangeStatusYBS;
      newState.FormCustomCustomerStatus.StatusYBS = changeStatusYBS.StatusYBS;

      return { ...newState };

    case 'CHANGE_TYPE_FITURE':
      const changeTypeFiture = action as IChangeTypeFiture;
      newState.CustomerFiture.TypeFiture = changeTypeFiture.TypeFiture;

      return { ...newState };
    case 'CHANGE_ID_FITURE':
      const changeIdFiture = action as IChangeIdFiture;
      newState.CustomerFiture.IdFiture = changeIdFiture.IdFiture;

      return { ...newState };

    case 'CHANGE_SMS_DELIVERY':
      const changeSmsDelivery = action as IChangeSmsDelivery;
      newState.CustomerFiture.SmsDelivery = changeSmsDelivery.SmsDelivery;

      return { ...newState };

    case 'CHANGE_SMS_DELIVERED':
      const changeSmsDelivered = action as IChangeSmsDelivered;
      newState.CustomerFiture.SmsDelivered = changeSmsDelivered.SmsDelivered;

      return { ...newState };

    case 'CHANGE_CALL':
      const changeCall = action as IChangeCall;
      newState.CustomerFiture.Call = changeCall.Call;

      return { ...newState };

    case 'CHANGE_ISRADIUS':
      const changeIsRadius = action as IChangeIsRadius;
      newState.CustomerFiture.IsRadius = changeIsRadius.IsRadius;

      return { ...newState };

    case 'CHANGE_ISREQUESTPHONE':
      const changeIsRequestPhone = action as IChangeIsRequestPhone;
      newState.CustomerFiture.IsRequestPhone =
        changeIsRequestPhone.IsRequestPhone;

      return { ...newState };

    case 'CHANGE_ISHISTORY':
      const changeIsHistory = action as IChangeIsHistory;
      newState.CustomerFiture.IsHistory = changeIsHistory.IsHistory;

      return { ...newState };

    case 'CHANGE_RADIUS_METERS':
      const changeRadiusMeters = action as IChangeRadiusMeters;
      newState.CustomerFiture.RadiusMeters = changeRadiusMeters.RadiusMeters;

      return { ...newState };

    case 'CHANGE_SENDER_ALIAS':
      const changeSenderAlias = action as IChangeSenderAlias;
      newState.CustomerFiture.SenderAlias = changeSenderAlias.SenderAlias;

      return { ...newState };

    case 'CHANGE_SMS_DELIVERY_BODY':
      const changeSmsDeliveryBody = action as IChangeSmsDeliveryBody;
      newState.CustomerFiture.SmsDeliveryBody =
        changeSmsDeliveryBody.SmsDeliveryBody;

      return { ...newState };

    case 'CHANGE_SMS_DELIVERED_BODY':
      const changeSmsDeliveredBody = action as IChangeSmsDeliveredBody;
      newState.CustomerFiture.SmsDeliveredBody =
        changeSmsDeliveredBody.SmsDeliveredBody;

      return { ...newState };

    case 'SET_LIST_CUSTOMER':
      const setListCustomer = action as ISetListCustomer;
      newState.ListCustomer = setListCustomer.listCustomer;

      return { ...newState };

    case 'SET_LIST_CUSTOM_CSV_CUSTOMER':
      const setListCustomCsvCustomer = action as ISetListCustomCsvCustomer;
      newState.ListCustomCsvCustomer =
        setListCustomCsvCustomer.ListCustomCsvCustomer;

      return { ...newState };

    case 'SET_DEFAULT_DATA_STATUS':
      const setDefaultDataStatus = action as ISetDefaultStatus;
      newState.DefaultDataStatus = setDefaultDataStatus.DefaultDataStatus;

      return { ...newState };

    case 'SET_CUSTOM_DATA_STATUS':
      const setCustomDataStatus = action as ISetCustomStatus;
      newState.CustomDataStatus = setCustomDataStatus.CustomDataStatus;

      return { ...newState };

    case 'CHANGE_Id_CSV':
      const setIdCsv = action as ISetIdCsv;
      newState.FormCustomCsvCustomer.IdCsv = setIdCsv.IdCsv;

      return { ...newState };

    case 'CHANGE_CUSTOMER_NAME_CSV':
      const setCustomerNameCsv = action as ISetCustomerNameCsv;
      newState.FormCustomCsvCustomer.CustomerNameCsv =
        setCustomerNameCsv.CustomerNameCsv;

      return { ...newState };

    case 'CHANGE_FILE_SETTING_NAME_CSV':
      const setFileSettingNameCsv = action as ISetFileSettingNameCsv;
      newState.FormCustomCsvCustomer.FileSettingName =
        setFileSettingNameCsv.FileSettingName;

      return { ...newState };

    case 'CHANGE_STATUS_DEFAULT_CSV':
      const setStatusDefaultCsv = action as ISetStatusDefaultCsv;
      newState.FormCustomCsvCustomer.StatusDefault =
        setStatusDefaultCsv.StatusDefault;

      return { ...newState };

    case 'CHANGE_REVERSE_BARCODE_CSV':
      const setReverseBarcodeCsv = action as ISetReverseBarcodeCsv;
      newState.FormCustomCsvCustomer.ReverseBarcode =
        setReverseBarcodeCsv.ReverseBarcode;

      return { ...newState };

    case 'CHANGE_DELIMITED_CSV':
      const setDelimitedCsv = action as ISetDelimitedCsv;
      newState.FormCustomCsvCustomer.DelimitedCsv =
        setDelimitedCsv.DelimitedCsv;

      return { ...newState };
    case 'CHANGE_FILE_TYPE':
      const changeFileType = action as ISetFileType;
      newState.FormCustomCsvCustomer.FileType = changeFileType.FileType;

      return { ...newState };

    case 'CHANGE_CYCLE_CSV':
      const setCycleCsv = action as ISetCycleCsv;
      newState.FormCustomCsvCustomer.CycleCsv = setCycleCsv.CycleCsv;

      return { ...newState };

    case 'CHANGE_FORMAT_CYCLE_CSV':
      const setFormatCycleCsv = action as ISetFormatCycleCsv;
      newState.FormCustomCsvCustomer.FormatCycleCsv =
        setFormatCycleCsv.FormatCycleCsv;

      return { ...newState };

    case 'CHANGE_CITY_CSV':
      const setCityCsv = action as ISetCityCsv;
      newState.FormCustomCsvCustomer.CityCsv = setCityCsv.CityCsv;

      return { ...newState };

    case 'CHANGE_ADDRESS_CSV':
      const setAddressCsv = action as ISetAddressCsv;
      newState.FormCustomCsvCustomer.AddressCsv = setAddressCsv.AddressCsv;

      return { ...newState };

    case 'CHANGE_ADDRESS_OFFICE':
      const setAddressOffice = action as ISetAddressOffice;
      newState.FormCustomCsvCustomer.AddressOffice =
        setAddressOffice.AddressOffice;

      return { ...newState };

    case 'CHANGE_LIST_ADDRESS_CSV':
      const setListAddressCsv = action as ISetListAddressCsv;
      newState.FormCustomCsvCustomer.ListAddressCsv =
        setListAddressCsv.ListAddressCsv;

      return { ...newState };

    case 'CHANGE_LIST_ADDRESS_OFFICE':
      const setListAddressOffice = action as ISetListAddressOffice;
      newState.FormCustomCsvCustomer.ListAddressOffice =
        setListAddressOffice.ListAddressOffice;

      return { ...newState };

    case 'CHANGE_BARCODE_CSV':
      const setBarcodeCsv = action as ISetBarcodeCsv;
      newState.FormCustomCsvCustomer.BarcodeCsv = setBarcodeCsv.BarcodeCsv;

      return { ...newState };

    case 'CHANGE_OWNER_NAME_CSV':
      const setOwnerNameCsv = action as ISetOwnerNameCsv;
      newState.FormCustomCsvCustomer.OwnerNameCsv =
        setOwnerNameCsv.OwnerNameCsv;

      return { ...newState };

    case 'CHANGE_POSTAL_CODE_CSV':
      const setPostalCodeCsv = action as ISetPostalCodeCsv;
      newState.FormCustomCsvCustomer.PostalCodeCsv =
        setPostalCodeCsv.PostalCodeCsv;

      return { ...newState };

    case 'CHANGE_NO_TELP_CSV':
      const setNoTelpCsv = action as ISetNoTelpCsv;
      newState.FormCustomCsvCustomer.NoTelpCsv = setNoTelpCsv.NoTelpCsv;

      return { ...newState };
    case 'CHANGE_CALL_LINK_CSV':
      const setCallLinkCsv = action as ISetCallLinkCsv;
      newState.FormCustomCsvCustomer.CallLinkCsv = setCallLinkCsv.CallLinkCsv;

      return { ...newState };
    case 'CHANGE_ADDITIONAL_DATA_CSV':
      const setAdditionalDataCsv = action as ISetAdditionalDataCsv;
      newState.FormCustomCsvCustomer.AdditionalDataCsv =
        setAdditionalDataCsv.AdditionalDataCsv;

      return { ...newState };
    case 'CHANGE_LIST_ADDITIONAL_DATA_CSV':
      const setListAdditionalDataCsv = action as ISetListAdditionalDataCsv;
      newState.FormCustomCsvCustomer.ListAdditionalDataCsv =
        setListAdditionalDataCsv.ListAdditionalDataCsv;

      return { ...newState };

    case 'CHANGE_TYPE_FORM_CSV':
      const setTypeFormCsv = action as ISetTypeFormCsv;
      newState.FormCustomCsvCustomer.TypeFormCsv = setTypeFormCsv.TypeFormCsv;

      return { ...newState };

    case 'CHANGE_GROUP_NAME_FORM_CSV':
      const setGroupNameCsv = action as ISetGroupNameCsv;
      newState.FormCustomCsvCustomer.GroupNameCsv =
        setGroupNameCsv.GroupNameCsv;

      return { ...newState };

    case 'CHANGE_SEARCH_TYPE_FORM_CSV':
      const setSearchTypeCsv = action as ISetSearchTypeCsv;
      newState.FormCustomCsvCustomer.SearchTypeCsv =
        setSearchTypeCsv.SearchTypeCsv;

      return { ...newState };

    case 'CHANGE_KEY_TYPE_FORM_CSV':
      const setSearchKeyCsv = action as ISetSearchKeyCsv;
      newState.FormCustomCsvCustomer.SearchKeyCsv =
        setSearchKeyCsv.SearchKeyCsv;

      return { ...newState };

    case 'CHANGE_CB_CALL_CENTER_FORM_CSV':
      const setCBCallCenterCsv = action as ISetCheckboxCallCenterCsv;
      newState.FormCustomCsvCustomer.CheckboxCallCenter =
        setCBCallCenterCsv.CheckboxCallCenter;

      return { ...newState };

    case 'CHANGE_WAYBILL_FORM_CSV':
      const setWaybillCsv = action as ISetWaybillCsv;
      newState.FormCustomCsvCustomer.Waybill = setWaybillCsv.Waybill;

      return { ...newState };

    case 'CHANGE_ADDRESS_UPDATE_FORM_CSV':
      const setAddressUpdateCsv = action as ISetAddressUpdateCsv;
      newState.FormCustomCsvCustomer.AddressUpdate =
        setAddressUpdateCsv.AddressUpdate;

      return { ...newState };

    case 'CHANGE_TYPE_FORM_CONFIG_REPORT':
      const changeTypeFormConfigReport = action as IChangeTypeFormConfigReport;
      newState.FormCustomerConfigReport.TypeFormConfigReport =
        changeTypeFormConfigReport.TypeFormConfigReport;

      return { ...newState };

    case 'CHANGE_ID_CUSTOMER_REPORT':
      const changeIdCustomerReport = action as IChangeIdCustomerReport;
      newState.FormCustomerConfigReport.IdCustomerReport =
        changeIdCustomerReport.IdCustomerReport;

      return { ...newState };

    case 'CHANGE_CUSTOMER_NAME_REPORT':
      const changeCustomerNameReport = action as IChangeCustomerNameReport;
      newState.FormCustomerConfigReport.CustomerNameReport =
        changeCustomerNameReport.CustomerNameReport;

      return { ...newState };

    case 'CHANGE_FORMAT_REPORT':
      const changeFormatReport = action as IChangeFormatReport;
      newState.FormCustomerConfigReport.FormatReport =
        changeFormatReport.FormatReport;

      return { ...newState };

    case 'CHANGE_PERIODE_REPORT':
      const changePeriodeReport = action as IChangePeriodeReport;
      newState.FormCustomerConfigReport.PeriodeReport =
        changePeriodeReport.PeriodeReport;

      return { ...newState };

    case 'CHANGE_DELIMITED_REPORT':
      const changeDelimitedReport = action as IChangeDelimitedReport;
      newState.FormCustomerConfigReport.DelimitedReport =
        changeDelimitedReport.DelimitedReport;

      return { ...newState };

    case 'CHANGE_WITHOUT_HEADER_REPORT':
      const changeWithoutReport = action as IChangeWithoutHeaderReport;
      newState.FormCustomerConfigReport.WithoutHeader =
        changeWithoutReport.WithoutHeader;

      return { ...newState };

    case 'CHANGE_SHOW_IN_PORTAL_REPORT':
      const changeShowInPortalReport = action as IChangeShowInPortalReport;
      newState.FormCustomerConfigReport.ShowInPortal =
        changeShowInPortalReport.ShowInPortal;

      return { ...newState };

    case 'CHANGE_SETTING_REPORT':
      const changeSettingReport = action as IChangeSettingReport;
      newState.FormCustomerConfigReport.SettingReport =
        changeSettingReport.SettingReport;

      return { ...newState };

    case 'CHANGE_SETTING_REPORT_NAME':
      const changeSettingReportName = action as IChangeSettingReportName;
      newState.FormCustomerConfigReport.SettingReportName =
        changeSettingReportName.SettingReportName;

      return { ...newState };

    case 'SET_LIST_CUSTOMER_REPORT':
      const setListCustomerReport = action as ISetListCustomerReport;
      newState.ListCustomerReport = setListCustomerReport.ListCustomerReport;

      return { ...newState };

    case 'CHANGE_ID_CUSTOMER_ACCOUNT':
      const changeIdCustomerAccount = action as IChangeIdCustomerAccount;
      newState.CustomerAccount.IdCustomerAccount =
        changeIdCustomerAccount.IdCustomerAccount;

      return { ...newState };

    case 'CHANGE_FIRST_NAME_ACCOUNT':
      const changeFirstNameAccount = action as IChangeFirstNameAccount;
      newState.CustomerAccount.FirstName = changeFirstNameAccount.FirstName;

      return { ...newState };

    case 'CHANGE_LAST_NAME_ACCOUNT':
      const changeLastNameAccount = action as IChangeLastNameAccount;
      newState.CustomerAccount.LastName = changeLastNameAccount.LastName;

      return { ...newState };

    case 'CHANGE_PHONE_NUMBER_ACCOUNT':
      const changePhoneNumberAccount = action as IChangePhoneNumberAccount;
      newState.CustomerAccount.PhoneNumber =
        changePhoneNumberAccount.PhoneNumber;

      return { ...newState };

    case 'CHANGE_EMAIL_ACCOUNT':
      const changeEmailAccount = action as IChangeEmailAccount;
      newState.CustomerAccount.Email = changeEmailAccount.Email;

      return { ...newState };

    case 'CHANGE_USERNAME_ACCOUNT':
      const changeUsernameAccount = action as IChangeUsernameAccount;
      newState.CustomerAccount.Username = changeUsernameAccount.Username;

      return { ...newState };

    case 'CHANGE_ADDRESS_ACCOUNT':
      const changeAddressAccount = action as IChangeAddressAccount;
      newState.CustomerAccount.Address = changeAddressAccount.Address;

      return { ...newState };

    case 'CHANGE_STATUS_ACCOUNT':
      const changeStatusAccount = action as IChangeStatusAccount;
      newState.CustomerAccount.StatusAccount =
        changeStatusAccount.StatusAccount;

      return { ...newState };

    case 'SET_CUSTOMER_ACCOUNT':
      const setCustomerListAccount = action as ISetCustomerListAccount;
      newState.CustomerAccount.CustomerListAccount =
        setCustomerListAccount.CustomerListAccount;
      return { ...newState };

    case 'CHANGE_CUSTOMER_PRODUCT_ID':
      const setCustomerProductId = action as ISetCustomerProductId;
      newState.CustomerAccount.CustomerProductId =
        setCustomerProductId.CustomerProductId;
      return { ...newState };

    case 'SET_LIST_CUSTOMER_CSV_TYPE':
      const setListCustomerCsvType = action as ISetListCustomerCsvType;
      newState.ListCustomerCsvType = setListCustomerCsvType.ListCustomerCsvType;

      return { ...newState };

    case 'SET_TABLE_CUSTOM_REPORT_DATA_CUSTOMER':
      const setTableCustomReportDataCustomer =
        action as ISetTableCustomReportDataCustomer;
      newState.FormCustomerConfigReport.CustomDataReport.TableCustomReportDataCustomer =
        setTableCustomReportDataCustomer.TableCustomReportDataCustomer;

      return { ...newState };

    case 'SET_HEADER_CUSTOM_REPORT_CUSTOMER':
      const setHeaderCustomReportCustomer =
        action as ISetHeaderCustomReportCustomer;
      newState.FormCustomerConfigReport.FieldCustomReportData.HeaderCustomReportCustomer =
        setHeaderCustomReportCustomer.HeaderCustomReportCustomer;

      return { ...newState };

    case 'CLEAR_TYPE_LOADING_CUSTOMER':
      newState.PropertiesCustomer.TypeLoadingCustomer = null;
      return { ...newState };

    case 'CLEAR_LIST_COLUMN_CUSTOM_REPORT_CUSTOMER':
      newState.FormCustomerConfigReport.FieldCustomReportData.ListColumnCustomReportCustomer =
        [];
      return { ...newState };

    case 'CLEAR_FIELD_ALL_CUSTOM_REPORT_CUSTOMER':
      newState.FormCustomerConfigReport.FieldCustomReportData.HeaderCustomReportCustomer =
        null;
      newState.FormCustomerConfigReport.FieldCustomReportData.SelectValueCustomReportCustomer =
        false;
      newState.FormCustomerConfigReport.FieldCustomReportData.ValueCustomReportCustomer =
        null;
      newState.FormCustomerConfigReport.FieldCustomReportData.SourceCustomReportCustomer =
        null;
      newState.FormCustomerConfigReport.FieldCustomReportData.ColumnCustomReportCustomer =
        null;
      newState.FormCustomerConfigReport.FieldCustomReportData.ListColumnCustomReportCustomer =
        [];
      newState.FormCustomerConfigReport.FieldCustomReportData.CheckBoxSubColumnCustomReportCustomer =
        false;
      newState.FormCustomerConfigReport.FieldCustomReportData.SubColumnCustomReportCustomer =
        null;
      newState.FormCustomerConfigReport.FieldCustomReportData.ListSubColumnCustomReportCustomer =
        [];
      newState.FormCustomerConfigReport.FieldCustomReportData.FormatValueCustomReportCustomer =
        null;
      newState.FormCustomerConfigReport.FieldCustomReportData.FormatColumnCustomReportCustomer =
        null;
      newState.FormCustomerConfigReport.FieldCustomReportData.PositionCustomReportCustomer =
        null;
      newState.FormCustomerConfigReport.FieldCustomReportData.IdCustomReportCustomer =
        null;

      return { ...newState };

    case 'CLEAR_ADDRESS_CSV':
      newState.FormCustomCsvCustomer.AddressCsv = null;
      return { ...newState };

    case 'CLEAR_ADDRESS_OFFICE':
      newState.FormCustomCsvCustomer.AddressOffice = null;

      return { ...newState };

    case 'CLEAR_CYCLE_CSV':
      newState.FormCustomCsvCustomer.CycleCsv = null;

      return { ...newState };
    case 'CLEAR_ADDITIONAL_DATA_CSV':
      newState.FormCustomCsvCustomer.AdditionalDataCsv = null;

      return { ...newState };
    case 'CLEAR_LIST_ADDRESS_CSV':
      newState.FormCustomCsvCustomer.ListAddressCsv = [];
      return { ...newState };

    case 'CLEAR_LIST_ADDRESS_OFFICE':
      newState.FormCustomCsvCustomer.ListAddressOffice = [];
      return { ...newState };

    case 'CLEAR_LIST_ADDITIONAL_DATA_CSV':
      newState.FormCustomCsvCustomer.ListAdditionalDataCsv = [];

      return { ...newState };

    case 'CHANGE_CLEAR_ID_CUSTOMER':
      newState.PropertiesCustomer.IdCustomer = null;

      return { ...newState };

    case 'CHANGE_CLEAR_ID_STATUS':
      newState.PropertiesCustomer.IdStatus = null;

      return { ...newState };

    case 'CLEAR_EDIT_CUSTOMER':
      newState.PropertiesCustomer.EditCustomer = null;

      return { ...newState };

    case 'CLEAR_TABLE_CUSTOM_REPORT_DATA_CUSTOMER':
      newState.FormCustomerConfigReport.CustomDataReport.TableCustomReportDataCustomer =
        [];

      return { ...newState };

    case 'CLEAR_ALL_DATA_CUSTOM_CSV_CUSTOMER':
      newState.FormCustomCsvCustomer.IdCsv = null;
      newState.FormCustomCsvCustomer.FileSettingName = null;
      newState.FormCustomCsvCustomer.CustomerNameCsv = null;
      newState.FormCustomCsvCustomer.DelimitedCsv = null;
      newState.FormCustomCsvCustomer.FileType = null;
      newState.FormCustomCsvCustomer.CycleCsv = null;
      newState.FormCustomCsvCustomer.FormatCycleCsv = 'dd/MM/yyyy';
      newState.FormCustomCsvCustomer.StatusDefault = false;
      newState.FormCustomCsvCustomer.ReverseBarcode = false;
      newState.FormCustomCsvCustomer.CustomerNameCsv = null;
      newState.FormCustomCsvCustomer.CityCsv = null;
      newState.FormCustomCsvCustomer.AddressCsv = null;
      newState.FormCustomCsvCustomer.AddressOffice = null;
      newState.FormCustomCsvCustomer.ListAddressCsv = [];
      newState.FormCustomCsvCustomer.ListAddressOffice = [];
      newState.FormCustomCsvCustomer.ListAdditionalDataCsv = [];
      newState.FormCustomCsvCustomer.AdditionalDataCsv = null;
      newState.FormCustomCsvCustomer.BarcodeCsv = null;
      newState.FormCustomCsvCustomer.OwnerNameCsv = null;
      newState.FormCustomCsvCustomer.PostalCodeCsv = null;
      newState.FormCustomCsvCustomer.NoTelpCsv = null;
      newState.FormCustomCsvCustomer.CallLinkCsv = null;
      newState.FormCustomCsvCustomer.TypeFormCsv = null;
      newState.FormCustomCsvCustomer.GroupNameCsv = null;
      newState.FormCustomCsvCustomer.SearchTypeCsv = null;
      newState.FormCustomCsvCustomer.SearchKeyCsv = null;
      newState.FormCustomCsvCustomer.CheckboxCallCenter = false;
      newState.FormCustomCsvCustomer.Waybill = null;
      newState.FormCustomCsvCustomer.AddressUpdate = null;

      return { ...newState };

    case 'CLEAR_DATA_CUSTOMER_ACCOUNT':
      newState.CustomerAccount.IdCustomerAccount = null;
      newState.CustomerAccount.FirstName = null;
      newState.CustomerAccount.LastName = null;
      newState.CustomerAccount.PhoneNumber = null;
      newState.CustomerAccount.Email = null;
      newState.CustomerAccount.Username = null;
      newState.CustomerAccount.Address = null;
      newState.CustomerAccount.StatusAccount = true;
      newState.CustomerAccount.CustomerProductId = [];
      return { ...newState };

    case 'CHANGE_CLEAR_DATA_CUSTOMER':
      newState.PropertiesCustomer.IdStatus = null;
      newState.PropertiesCustomer.IdCustomer = null;
      newState.PropertiesCustomer.EditCustomer = null;
      newState.DetailCustomer.CustomerName = null;
      newState.DetailCustomer.CustomerCode = null;
      newState.DetailCustomer.CustomerType = null;
      newState.DetailCustomer.CustomerTypeOther = null;
      newState.DetailCustomer.CustomerTelephone = null;
      newState.DetailCustomer.CustomerEmail = null;
      newState.DetailCustomer.CustomerAddress = null;
      newState.DetailCustomer.CustomerAccess = [];
      return { ...newState };

    case 'CHANGE_CLEAR_FIELD_CUSTOMER':
      newState.DetailCustomer.CustomerName = null;
      newState.DetailCustomer.CustomerCode = null;
      newState.DetailCustomer.CustomerType = null;
      newState.DetailCustomer.CustomerTypeOther = null;
      newState.DetailCustomer.CustomerTelephone = null;
      newState.DetailCustomer.CustomerEmail = null;
      newState.DetailCustomer.CustomerAddress = null;
      newState.DetailCustomer.CustomerAccess = [];
      newState.ListCustomer = [];
      return { ...newState };

    case 'CLEAR_DATA_CUSTOMER_STATUS_FORM':
      newState.FormCustomCustomerStatus.StatusPosition = null;
      newState.FormCustomCustomerStatus.StatusCode = null;
      newState.FormCustomCustomerStatus.StatusDescription = null;
      newState.FormCustomCustomerStatus.StatusLabel = null;
      newState.FormCustomCustomerStatus.StatusAttempt = false;
      newState.FormCustomCustomerStatus.StatusFreeText = false;
      newState.FormCustomCustomerStatus.StatusYBS = false;
      return { ...newState };

    case 'CLEAR_CUSTOMER_CONFIG_REPORT':
      newState.FormCustomerConfigReport.IdCustomerReport = null;
      newState.FormCustomerConfigReport.CustomerNameReport = null;
      newState.FormCustomerConfigReport.FormatReport = null;
      newState.FormCustomerConfigReport.PeriodeReport = null;
      newState.FormCustomerConfigReport.DelimitedReport = null;
      newState.FormCustomerConfigReport.WithoutHeader = false;
      newState.FormCustomerConfigReport.ShowInPortal = false;
      newState.FormCustomerConfigReport.SettingReport = null;
      newState.FormCustomerConfigReport.SettingReportName = null;
      newState.FormCustomerConfigReport.CustomDataReport.TableCustomReportDataCustomer =
        [];

      return { ...newState };

    case 'CLEAR_DELIMITED_REPORT':
      newState.FormCustomerConfigReport.DelimitedReport = null;

      return { ...newState };

    case 'CLEAR_CUSTOMER_TYPE_FORM_CONFIG_REPORT':
      newState.FormCustomerConfigReport.TypeFormConfigReport = null;

      return { ...newState };

    case 'CLEAR_CUSTOMER_FITURE':
      newState.CustomerFiture.TypeFiture = null;
      newState.CustomerFiture.IdFiture = null;
      newState.CustomerFiture.SmsDelivery = false;
      newState.CustomerFiture.SmsDelivered = false;
      newState.CustomerFiture.Call = false;
      newState.CustomerFiture.SenderAlias = null;
      newState.CustomerFiture.SmsDeliveryBody = null;
      newState.CustomerFiture.SmsDeliveredBody = null;
      return { ...newState };

    case 'CLEAR_FITURE_SMS_DELIVERY_BODY':
      newState.CustomerFiture.SmsDeliveryBody = null;
      return { ...newState };

    case 'CLEAR_FITURE_SMS_DELIVERED_BODY':
      newState.CustomerFiture.SmsDeliveredBody = null;
      return { ...newState };

    case 'CLEAR_LIST_CUSTOMER_CSV_TYPE':
      newState.ListCustomerCsvType = [];
      return { ...newState };

    case 'CLEAR_LIST_CUSTOMER_REPORT':
      newState.ListCustomerReport = [];
      return { ...newState };

    case 'CLEAR_LIST_CUSTOMER':
      newState.ListCustomer = [];
      return { ...newState };

    case 'CLEAR_LIST_CUSTOM_CSV_CUSTOMER':
      newState.ListCustomCsvCustomer = [];
      return { ...newState };

    case 'CHANGE_CLEAR_REASON_STAT':
      newState.PropertiesCustomer.ReasonDeliveryStatus = null;
      return { ...newState };

    case 'CHANGE_CLEAR_RADIOBUTTON':
      newState.PropertiesCustomer.Radiobutton = null;
      return { ...newState };

    case 'CLEAR_CTYPE':
      newState.PropertiesCustomer.ChangeType = null;
      return { ...newState };

    case 'CLEAR_CSV_TEMPLATE':
      newState.PropertiesCustomer.CsvTemplate = null;
      return { ...newState };

    case 'CLEAR_DATA_CUSTOMER_TYPE_OTHER':
      newState.DetailCustomer.CustomerTypeOther = null;
      return { ...newState };

    case 'CLEAR_DEFAULT_DATA_STATUS':
      newState.DefaultDataStatus = [];
      return { ...newState };

    case 'CLEAR_CUSTOM_DATA_STATUS':
      newState.CustomDataStatus = [];
      return { ...newState };

    case 'CLEAR_CUSTOMER_ACCOUNT':
      newState.CustomerAccount.CustomerListAccount = [];
      return { ...newState };

    case 'CLEAR_LOADING_CUSTOMER':
      newState.PropertiesCustomer.LoadingCustomer = false;
      newState.PropertiesCustomer.TypeLoadingCustomer = null;
      return { ...newState };

    case 'CLEAR_LOADING_STATUS':
      newState.PropertiesCustomer.LoadingStatus = false;
      newState.PropertiesCustomer.TypeLoadingStatus = null;
      return { ...newState };

    case 'SET_ID_CUSTOM_REPORT_CUSTOMER_CDRC':
      const setIdCustomReportCustomerCdrc =
        action as ISetCdrcIdCustomReportCustomer;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.IdCustomReportCustomer =
        setIdCustomReportCustomerCdrc.IdCustomReportCustomer;

      return { ...newState };

    case 'SET_HEADER_CDRC':
      const setHeader = action as ISetCdrcHeader;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.Header =
        setHeader.Header;

      return { ...newState };

    case 'SET_FORMAT_CDRC':
      const setFormat = action as ISetCdrcFormat;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.Format =
        setFormat.Format;

      return { ...newState };

    case 'SET_CHECKBOX_PETIK_CDRC':
      const setCheckBoxPetik = action as ISetCdrcCheckBoxPetik;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.CheckBoxPetik =
        setCheckBoxPetik.CheckBoxPetik;
      return { ...newState };

    case 'SET_CHECKBOX_OTHERS_CDRC':
      const setCheckBoxOthers = action as ISetCdrcCheckBoxOthers;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.CheckBoxOthers =
        setCheckBoxOthers.CheckBoxOthers;
      return { ...newState };

    case 'SET_POSITION_CDRC':
      const setPosition = action as ISetCdrcPosition;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.Position =
        setPosition.Position;

      return { ...newState };

    case 'SET_VALUE_CDRC':
      newState.FormCustomerConfigReport.CustomDataReportCustomer.Value =
        !newState.FormCustomerConfigReport.CustomDataReportCustomer.Value;

      return { ...newState };

    case 'SET_VALUE_VALUE_CDRC':
      const setValueValue = action as ISetCdrcValueValue;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.Value =
        setValueValue.Value;

      return { ...newState };

    case 'SET_VALUE_DEFAULT_CDRC':
      const setValueDefault = action as ISetCdrcValueDefault;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ValueDefault =
        setValueDefault.ValueDefault;

      return { ...newState };

    case 'SET_SOURCE_TABLE_CDRC':
      const setSourceTable = action as ISetCdrcSourceTable;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SourceTable =
        setSourceTable.SourceTable;

      return { ...newState };

    case 'SET_FORMAT_TYPE_CDRC':
      const setFormatType = action as ISetCdrcFormatType;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.FormatType =
        setFormatType.FormatType;

      return { ...newState };

    case 'SET_HISTORY_TYPE_CDRC':
      const setHistoryType = action as ISetCdrcHistoryType;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.HistoryType =
        setHistoryType.HistoryType;

      return { ...newState };

    case 'SET_FORMAT_COLUMN_CDRC':
      const setFormatColumn = action as ISetCdrcFormatColumn;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.FormatColumn =
        setFormatColumn.FormatColumn;

      return { ...newState };

    case 'CHANGE_SETTING_CHARACTER_LENGTH':
      const changeSettingCharLength = action as IChangeCharacterLength;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.CharacterLength =
        changeSettingCharLength.CharacterLength;

      return { ...newState };

    case 'SET_SOURCE_COLUMN_CDRC':
      const setSourceColumn = action as ISetCdrcSourceColumn;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SourceColumn =
        setSourceColumn.SourceColumn;

      return { ...newState };

    case 'SET_SOURCE_COLUMN_SEC_CDRC':
      const setSourceColumnSec = action as ISetCdrcSourceColumnSec;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SourceColumnSec =
        setSourceColumnSec.SourceColumnSec;

      return { ...newState };

    case 'SET_DELIVERY_TO_CDRC':
      const setDeliveryTo = action as ISetCdrcDeliveryTo;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.DeliveryTo =
        setDeliveryTo.DeliveryTo;

      return { ...newState };

    case 'SET_SUB_COLUMN_CDRC':
      const setSubColumn = action as ISetCdrcSubColumn;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SubColumn =
        setSubColumn.SubColumn;

      return { ...newState };

    case 'SET_SUB_COLUMN_SEC_CDRC':
      const setSubColumnSec = action as ISetCdrcSubColumnSec;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SubColumnSec =
        setSubColumnSec.SubColumnSec;

      return { ...newState };

    case 'SET_REPLACE_SUCCESS_CDRC':
      const setReplaceSuccess = action as ISetCdrcReplaceSuccess;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceSuccess =
        setReplaceSuccess.ReplaceSuccess;

      return { ...newState };

    case 'SET_REPLACE_RETURN_CDRC':
      const setReplaceReturn = action as ISetCdrcReplaceReturn;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceReturn =
        setReplaceReturn.ReplaceReturn;

      return { ...newState };

    case 'SET_REPLACE_ON_PROCESS_CDRC':
      const setReplaceProcess = action as ISetCdrcReplaceOnProcess;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceOnProcess =
        setReplaceProcess.ReplaceOnProcess;

      return { ...newState };

    case 'SET_REPLACE_ON_HOLD_CDRC':
      const setReplaceHold = action as ISetCdrcReplaceOnHold;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceOnHold =
        setReplaceHold.ReplaceOnHold;

      return { ...newState };

    case 'SET_LIST_COLUMN_CDRC':
      const setListSourceColumn = action as ISetCdrcListSourceColumn;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSourceColumn =
        setListSourceColumn.ListSourceColumn;

      return { ...newState };

    case 'SET_LIST_COLUMN_SEC_CDRC':
      const setListSourceColumnSec = action as ISetCdrcListSourceColumnSec;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSourceColumnSec =
        setListSourceColumnSec.ListSourceColumnSec;

      return { ...newState };

    case 'SET_LIST_SUB_COLUMN_CDRC':
      const setListSubColumn = action as ISetCdrcListSubColumn;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSubColumn =
        setListSubColumn.ListSubColumn;

      return { ...newState };

    case 'SET_LIST_SUB_COLUMN_SEC_CDRC':
      const setListSubColumnSec = action as ISetCdrcListSubColumnSec;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSubColumnSec =
        setListSubColumnSec.ListSubColumnSec;

      return { ...newState };

    case 'CLEAR_SUB_COLUMN_CDRC':
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SubColumn =
        null;

      return { ...newState };
    case 'CLEAR_FORMAT_COLUMN_CDRC':
      newState.FormCustomerConfigReport.CustomDataReportCustomer.FormatColumn =
        null;

      return { ...newState };

    case 'CLEAR_ON_CLICK_VALUE_CDRC':
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ValueDefault =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SourceTable =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.FormatType =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.HistoryType =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.FormatColumn =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.CharacterLength =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SourceColumn =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SourceColumnSec =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSourceColumn =
        [];
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSourceColumnSec =
        [];
      newState.FormCustomerConfigReport.CustomDataReportCustomer.DeliveryTo =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SubColumn =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SubColumnSec =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSubColumn =
        [];
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSubColumnSec =
        [];
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceSuccess =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceReturn =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceOnProcess =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceOnHold =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.CheckBoxPetik =
        false;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.CheckBoxOthers =
        false;

      return { ...newState };

    case 'CLEAR_SOURCE_TABLE_CDRC':
      newState.FormCustomerConfigReport.CustomDataReportCustomer.FormatType =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.HistoryType =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.FormatColumn =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.CharacterLength =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SourceColumn =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SourceColumnSec =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSourceColumn =
        [];
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSourceColumnSec =
        [];
      newState.FormCustomerConfigReport.CustomDataReportCustomer.DeliveryTo =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SubColumn =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SubColumnSec =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSubColumn =
        [];
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSubColumnSec =
        [];
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceSuccess =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceReturn =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceOnProcess =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceOnHold =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.CheckBoxPetik =
        false;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.CheckBoxOthers =
        false;

      return { ...newState };

    case 'CLEAR_ALL_CDRC':
      newState.FormCustomerConfigReport.CustomDataReportCustomer.IdCustomReportCustomer =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.Header = null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.Format = null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.Position =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.Value = false;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ValueDefault =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SourceTable =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.FormatType =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.HistoryType =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.FormatColumn =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.CharacterLength =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SourceColumn =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SourceColumnSec =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSourceColumn =
        [];
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSourceColumnSec =
        [];
      newState.FormCustomerConfigReport.CustomDataReportCustomer.DeliveryTo =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SubColumn =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.SubColumnSec =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSubColumn =
        [];
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ListSubColumnSec =
        [];
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceSuccess =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceReturn =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceOnProcess =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.ReplaceOnHold =
        null;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.CheckBoxPetik =
        false;
      newState.FormCustomerConfigReport.CustomDataReportCustomer.CheckBoxOthers =
        false;

      return { ...newState };

    case 'CHANGE_IS_MONTH_FILTER':
      const changeIsMonthFilter = action as IChangeIsMonthFilter;
      newState.CustomerFiture.isMonthFilter = changeIsMonthFilter.isMonthFilter;
      return { ...newState };

    case 'CHANGE_SEARCH_MONTH_FILTER':
      const changeSearchMonthFilter = action as IChangeSearchMonthFilter;
      newState.CustomerFiture.searchMonthFilter =
        changeSearchMonthFilter.searchMonthFilter;
      return { ...newState };
  }
  return state;
}
