import React from 'react';
import Button from 'components/Button';
import ModalDetailSortedDocumentComponent from '../Component/ModalDetailSortedDocumentComponent';
import { useMutation } from '@tanstack/react-query';
import { mutateDeleteSortDocument } from 'services/SortDocumentServices';
import { Icon, message, Modal } from 'antd';

interface IProps {
  modalDetailSortedDocument: boolean;
  setModalDetailSortedDocument: any;
  detailSortedDocument: any;
  setDetailSortedDocument: any;
  refetchDataSortedDocument(): void;
  refetchDataCountingSortedDocument(): void;
}
export default function ModalDetailSortedDocumentContainer(props: IProps) {
  const {
    modalDetailSortedDocument,
    setModalDetailSortedDocument,
    detailSortedDocument = null,
    setDetailSortedDocument,
    refetchDataSortedDocument,
    refetchDataCountingSortedDocument,
  } = props;

  const mutateUpdateScanSortDocument = useMutation(mutateDeleteSortDocument, {
    onSuccess: (_, variable) => {
      const filteredDetails = detailSortedDocument?.sortDocumentDetails.filter(
        (detail: any) => detail.waybillNumber !== variable.waybillNumber
      );
      setDetailSortedDocument({
        ...detailSortedDocument,
        sortDocumentDetails: filteredDetails,
      });
      message.success({
        content: 'Berhasil Dihapus',
        duration: 1,
      });
      refetchDataSortedDocument();
      refetchDataCountingSortedDocument();
    },
    onError: (error: any) => {
      console.log('error', error);
    },
  });
  const handleCancel = () => {
    setModalDetailSortedDocument(false);
    setDetailSortedDocument(null);
  };
  const columnData = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <b>No</b>
        </div>
      ),
      render: (_text, _record, index) => <span>{index + 1}</span>,
      key: 'no',
      width: 10,
    },
    {
      title: <b>Barcode</b>,
      dataIndex: 'barcode',
      key: 'barcode',
      width: 30,
    },
    {
      title: <b>Waybill</b>,
      dataIndex: 'waybillNumber',
      key: 'waybillNumber',
      width: 30,
    },
    {
      title: <b>Produk</b>,
      dataIndex: 'customerProduct',
      key: 'customerProduct',
      width: 30,
    },
    {
      title: <b>Di Scan Oleh</b>,
      dataIndex: 'userScan',
      key: 'userScan',
      width: 30,
    },
    {
      title: <b>Aksi</b>,
      key: 'action',
      width: 30,
      render: record => {
        return (
          <Button
            icon="delete"
            variant="danger"
            onClick={() => {
              Modal.confirm({
                className: 'modalConfirmationDelete',
                icon: null,
                title: (
                  <div style={{ textAlign: 'center', color: '#EA1A1A' }}>
                    <Icon
                      style={{
                        fontSize: '2rem',
                      }}
                      type="exclamation-circle"
                      theme="outlined"
                    />
                    <p
                      style={{
                        textTransform: 'capitalize',
                        fontSize: '1rem',
                        marginTop: '0.5rem',
                        marginBottom: '0',
                      }}
                    >
                      Apakah Anda yakin?
                    </p>
                  </div>
                ),
                content: (
                  <div style={{ textAlign: 'center' }}>
                    <p>Data yang telah di hapus tidak dapat dikembalikan</p>
                  </div>
                ),
                onOk() {
                  mutateUpdateScanSortDocument.mutate(record);
                },
                okText: 'YA',
                okButtonProps: {
                  type: 'primary',
                  style: {
                    color: 'white',
                    border: 'none',
                    borderRadius: '20px',
                    fontWeight: '500',
                    width: '80px',
                  },
                },
                cancelText: 'TIDAK',
                cancelButtonProps: {
                  style: {
                    backgroundColor: '#EA1A1A',
                    color: 'white',
                    border: 'none',
                    borderRadius: '20px',
                    fontWeight: '500',
                    width: '80px',
                  },
                },
              });
            }}
          />
        );
      },
    },
  ];

  return (
    <ModalDetailSortedDocumentComponent
      modalDetailSortedDocument={modalDetailSortedDocument}
      handleCancel={handleCancel}
      detailSortedDocument={detailSortedDocument}
      columnData={columnData}
      isLoadingMutation={mutateUpdateScanSortDocument.isLoading}
    />
  );
}
