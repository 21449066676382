import { Col, Row, Table } from 'antd';
import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import moment from 'moment-timezone';
import React from 'react';

interface IProps {
  modalDetailSortedDocument: boolean;
  handleCancel: () => void;
  detailSortedDocument: any;
  columnData: any;
  isLoadingMutation: boolean;
}
export default function ModalDetailSortedDocumentComponent(props: IProps) {
  const {
    modalDetailSortedDocument,
    handleCancel,
    detailSortedDocument,
    columnData,
    isLoadingMutation,
  } = props;
  return (
    <ModalMaterial
      width={'70%'}
      teksTitle="SCAN DOKUMEN"
      visible={modalDetailSortedDocument}
      onCancel={() => handleCancel()}
    >
      <SpinMaterial spinning={isLoadingMutation} size="large">
        <Row style={{ padding: '16px 36px' }} gutter={[0, 24]}>
          <Col span={12}>
            <b>Nama File</b>
            <p>{detailSortedDocument && detailSortedDocument.fileName}</p>
          </Col>
          <Col span={12}>
            <b>Tanggal Upload</b>
            <p>
              {detailSortedDocument &&
                moment(detailSortedDocument.uploadDeliveryTime).format(
                  'YYYY-MM-DD'
                )}
            </p>
          </Col>
          <Col span={12}></Col>
          <Col span={12}>
            <b>Tanggal Kirim</b>
            <p>
              {detailSortedDocument &&
                moment(detailSortedDocument.deliveryTime).format('YYYY-MM-DD')}
            </p>
          </Col>
          <Col span={12}></Col>
        </Row>

        <Table
          style={{ padding: '16px 24px' }}
          rowKey={(record: any) => record.id}
          scroll={{ x: 'auto' }}
          pagination={{
            pageSize: 10,
          }}
          bordered={false}
          dataSource={detailSortedDocument?.sortDocumentDetails || []}
          columns={columnData}
          footer={() => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                gap: 8,
              }}
            >
              <p style={{ margin: 0, fontWeight: 500, color: '#00AB44' }}>
                Total Entri (
                {detailSortedDocument?.sortDocumentDetails?.length || 0})
              </p>
            </div>
          )}
        />
      </SpinMaterial>
    </ModalMaterial>
  );
}
