import { Col, Icon, Row } from 'antd';
import React, { useMemo } from 'react';
import { Form, Field } from 'react-final-form';

import ButtonMaterial from '../../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../../materials/CardMaterial';
import SpinMaterial from '../../../../../materials/SpinMaterial';
import moment from 'moment';
import SelectField from 'components/SelectField';
import { SingleCheckbox } from 'components/SingleCheckbox';
import InputFile from 'components/InputFile';
import { getBase64, mapProductType } from 'lib/util';
import InputDatePicker from 'components/InputDatePicker';

interface IProps {
  formRef: React.MutableRefObject<any>;
  handleReset: () => void;
  handleSubmit: (values: any) => void;
  isFetchingCustomers: boolean;
  isFetchingProducts: boolean;
  isFetchingTemplate: boolean;
  isLoadingMutation: boolean;
  listCustomer: any;
  listProducts: any;
  listTemplateUpload: any;
  setSelectedCustomerId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
}
export default function UploadSoftcopyComponent(props: IProps) {
  const {
    listCustomer = [],
    setSelectedCustomerId,
    listProducts = [],
    listTemplateUpload = [],
    handleSubmit,
    handleReset,
    formRef,
    isFetchingCustomers,
    isFetchingProducts,
    isFetchingTemplate,
    isLoadingMutation,
  } = props;
  const minDate2 = (d: any) =>
    !d || d.isAfter(moment(new Date()).add(31, 'day').format('YYYY-MM-DD'));

  const initialValues = useMemo(
    () => ({
      customer: null,
      oldCustomerProductId: null,
      newCustomerProductId: null,
      isChangeProduct: false,
      oldProductType: null,
      newProductType: null,
      customerCsvId: null,
      fileName: null,
      fileCsv: null,
      deliveryTime: null,
      processTime: moment(),
    }),
    []
  );
  return (
    <div>
      <SpinMaterial
        spinning={isLoadingMutation}
        style={{}}
        size={'large'}
        content={
          <CardMaterial
            extra={''}
            title={<b style={{ color: '#11BEFF' }}>KIRIM ULANG</b>}
            style={{ borderRadius: '10px' }}
            content={
              <Form
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validate={values => {
                  const errors = {} as any;
                  const {
                    customer,
                    oldCustomerProductId,
                    newCustomerProductId,
                    customerCsvId,
                    fileName,
                    isChangeProduct,
                    processTime,
                  } = values;
                  if (!customer) {
                    errors.customer = 'Pelanggan wajib diisi';
                  }
                  if (customer && !oldCustomerProductId) {
                    errors.oldCustomerProductId = 'Produk wajib diisi';
                  }
                  if (customer && isChangeProduct && !newCustomerProductId) {
                    errors.newCustomerProductId = 'Produk wajib diisi';
                  }
                  if (oldCustomerProductId && !customerCsvId) {
                    errors.customerCsvId = 'Template unggah wajib diisi';
                  }
                  if (!fileName) {
                    errors.fileName = 'File wajib diisi';
                  }
                  if (!processTime) {
                    errors.processTime = 'Pelanggan wajib diisi';
                  }

                  return errors;
                }}
              >
                {({ values, handleSubmit, invalid, form, errors }) => {
                  formRef.current = form;
                  const {
                    customer,
                    oldCustomerProductId,
                    newCustomerProductId,
                    oldProductType,
                    newProductType,
                    customerCsvId,
                    fileCsv,
                    isChangeProduct,
                  } = values;

                  const validateButtonReset =
                    customer === null &&
                    oldCustomerProductId === null &&
                    newCustomerProductId === null &&
                    oldProductType === null &&
                    newProductType === null &&
                    customerCsvId === null &&
                    fileCsv === null
                      ? true
                      : customer !== null ||
                        oldCustomerProductId !== null ||
                        newCustomerProductId !== null ||
                        oldProductType !== null ||
                        newProductType !== null ||
                        customerCsvId !== null ||
                        fileCsv !== null
                      ? false
                      : true;

                  return (
                    <div>
                      <Row>
                        <Col span={12} style={{ paddingRight: '20px' }}>
                          <div>
                            <Field name="customer">
                              {({ input, meta }) => {
                                return (
                                  <SelectField
                                    validate
                                    label="Pelanggan"
                                    loading={isFetchingCustomers}
                                    style={{ width: '100%' }}
                                    data={listCustomer}
                                    disabled={false}
                                    placeholder="Pilih Pelanggan"
                                    onChange={value => {
                                      setSelectedCustomerId(value);
                                      return input.onChange(value);
                                    }}
                                    onBlur={value => {
                                      input.onBlur(value);
                                    }}
                                    value={input.value}
                                    validateStatus={
                                      meta.error && meta.touched
                                        ? 'error'
                                        : 'validating'
                                    }
                                    errorMessage={
                                      meta.error && meta.touched && meta.error
                                    }
                                  />
                                );
                              }}
                            </Field>
                          </div>
                          <div style={{ marginTop: 27 }}>
                            <span>
                              <b>Produk</b>
                            </span>
                            <Row>
                              <Col span={isChangeProduct ? 8 : 16}>
                                <Field name="oldCustomerProductId">
                                  {({ input, meta }) => {
                                    return (
                                      <SelectField
                                        validate
                                        loading={isFetchingProducts}
                                        data={listProducts}
                                        disabled={!customer}
                                        placeholder="Pilih Produk"
                                        onChange={value => {
                                          const selectedOldProduct =
                                            listProducts.find(
                                              item => item.id === value
                                            );
                                          form.change(
                                            'oldProductType',
                                            mapProductType(
                                              selectedOldProduct?.productType
                                            )
                                          );

                                          return input.onChange(value);
                                        }}
                                        onBlur={value => {
                                          input.onBlur(value);
                                        }}
                                        value={input.value}
                                        validateStatus={
                                          meta.error && meta.touched
                                            ? 'error'
                                            : 'validating'
                                        }
                                        errorMessage={
                                          meta.error &&
                                          meta.touched &&
                                          meta.error
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </Col>
                              {isChangeProduct ? (
                                <>
                                  <Col
                                    span={2}
                                    style={{
                                      height: '40px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div>
                                      <Icon
                                        type="swap-right"
                                        style={{ fontSize: 20 }}
                                      />
                                    </div>
                                  </Col>
                                  <Col span={8}>
                                    <Field name="newCustomerProductId">
                                      {({ input, meta }) => {
                                        return (
                                          <SelectField
                                            validate
                                            loading={isFetchingProducts}
                                            data={listProducts}
                                            disabled={!customer}
                                            placeholder="Pilih Produk"
                                            onChange={value => {
                                              const selectedNewProduct =
                                                listProducts.find(
                                                  item => item.id === value
                                                );
                                              form.change(
                                                'newProductType',
                                                mapProductType(
                                                  selectedNewProduct?.productType
                                                )
                                              );

                                              return input.onChange(value);
                                            }}
                                            onBlur={value => {
                                              input.onBlur(value);
                                            }}
                                            value={input.value}
                                            validateStatus={
                                              meta.error && meta.touched
                                                ? 'error'
                                                : 'validating'
                                            }
                                            errorMessage={
                                              meta.error &&
                                              meta.touched &&
                                              meta.error
                                            }
                                          />
                                        );
                                      }}
                                    </Field>
                                  </Col>
                                </>
                              ) : null}
                              <Col
                                span={6}
                                style={{
                                  textAlign: 'center',
                                  paddingLeft: '12px',
                                }}
                              >
                                <Field name="isChangeProduct">
                                  {({ input, meta }) => (
                                    <SingleCheckbox
                                      labelRight="Ubah Produk"
                                      input={input}
                                      meta={meta}
                                      onChange={value => {
                                        if (!value.target.checked) {
                                          form.change(
                                            'newCustomerProductId',
                                            null
                                          );
                                          form.change('newProductType', null);
                                        }
                                        return input.onChange(value);
                                      }}
                                      value={input.value}
                                    />
                                  )}
                                </Field>
                              </Col>
                            </Row>
                          </div>
                          <div style={{ marginTop: 27 }}>
                            <span>
                              <b>Jenis Dokumen</b>
                            </span>
                            <Row>
                              <Col span={isChangeProduct ? 11 : 24}>
                                <Field name="oldProductType">
                                  {({ input, meta }) => {
                                    return (
                                      <SelectField
                                        loading={isFetchingProducts}
                                        data={[]}
                                        disabled={true}
                                        placeholder="Pilih Jenis Dokumen"
                                        onChange={value =>
                                          input.onChange(value)
                                        }
                                        onBlur={value => {
                                          input.onBlur(value);
                                        }}
                                        value={input.value}
                                        validateStatus={
                                          meta.error && meta.touched
                                            ? 'error'
                                            : 'validating'
                                        }
                                        errorMessage={
                                          meta.error &&
                                          meta.touched &&
                                          meta.error
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </Col>
                              {isChangeProduct ? (
                                <>
                                  <Col
                                    span={2}
                                    style={{
                                      height: '40px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div>
                                      <Icon
                                        type="swap-right"
                                        style={{ fontSize: 20 }}
                                      />
                                    </div>
                                  </Col>
                                  <Col span={11}>
                                    <Field name="newProductType">
                                      {({ input, meta }) => {
                                        return (
                                          <SelectField
                                            loading={isFetchingProducts}
                                            data={[]}
                                            disabled={true}
                                            placeholder="Pilih Jenis Dokumen"
                                            onChange={value =>
                                              input.onChange(value)
                                            }
                                            onBlur={value => {
                                              input.onBlur(value);
                                            }}
                                            value={input.value}
                                            validateStatus={
                                              meta.error && meta.touched
                                                ? 'error'
                                                : 'validating'
                                            }
                                            errorMessage={
                                              meta.error &&
                                              meta.touched &&
                                              meta.error
                                            }
                                          />
                                        );
                                      }}
                                    </Field>
                                  </Col>
                                </>
                              ) : null}
                            </Row>
                          </div>
                        </Col>
                        <Col span={12} style={{ paddingLeft: '20px' }}>
                          <div>
                            <Field name="customerCsvId">
                              {({ input, meta }) => {
                                return (
                                  <SelectField
                                    validate
                                    label="Template Unggah"
                                    loading={isFetchingTemplate}
                                    data={listTemplateUpload}
                                    disabled={!oldCustomerProductId}
                                    placeholder="Pilih template unggah"
                                    onChange={value => input.onChange(value)}
                                    onBlur={value => {
                                      input.onBlur(value);
                                    }}
                                    value={input.value}
                                    validateStatus={
                                      meta.error && meta.touched
                                        ? 'error'
                                        : 'validating'
                                    }
                                    errorMessage={
                                      meta.error && meta.touched && meta.error
                                    }
                                  />
                                );
                              }}
                            </Field>
                          </div>
                          <div style={{ marginTop: 27 }}>
                            <Field name="fileName">
                              {({ input, meta }) => {
                                return (
                                  <InputFile
                                    label="Unggah File"
                                    accept=".csv"
                                    validateFile={
                                      meta.touched && meta.error && 'error'
                                    }
                                    error={
                                      meta.error && meta.touched && meta.error
                                    }
                                    handleChangeFile={async value => {
                                      const base64: any = await getBase64(
                                        value.file.originFileObj
                                      );
                                      form.change(
                                        'fileCsv',
                                        base64.split(',')[1]
                                      );
                                      form.change('fileName', value.file.name);
                                    }}
                                    value={input.value}
                                  />
                                );
                              }}
                            </Field>
                          </div>

                          <div style={{ marginTop: 27 }}>
                            <Field name="deliveryTime">
                              {({ input, meta }) => (
                                <InputDatePicker
                                  label="Tanggal Kirim"
                                  placeholder="Pilih Tanggal"
                                  onOpenChange={input.onBlur}
                                  onChange={value => input.onChange(value)}
                                  value={input.value}
                                  error={
                                    meta.touched &&
                                    meta.error !== undefined &&
                                    meta.error
                                  }
                                  formStyle={{ flex: 1 }}
                                  mode="date"
                                  disabledDate={minDate2}
                                />
                              )}
                            </Field>
                          </div>
                          <div style={{ marginTop: 27 }}>
                            <Field name="processTime">
                              {({ input, meta }) => (
                                <InputDatePicker
                                  label="Tanggal Upload"
                                  placeholder="Pilih Tanggal"
                                  onOpenChange={input.onBlur}
                                  onChange={value => input.onChange(value)}
                                  value={input.value}
                                  error={
                                    meta.touched &&
                                    meta.error !== undefined &&
                                    meta.error
                                  }
                                  formStyle={{ flex: 1 }}
                                  mode="date"
                                  disabledDate={minDate2}
                                />
                              )}
                            </Field>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '20px' }}>
                        <div style={{ marginLeft: 20, float: 'right' }}>
                          <ButtonMaterial
                            disabled={invalid}
                            handleSubmit={handleSubmit}
                            teksButton="Simpan"
                            size="middle"
                            shape="round"
                            type="primary"
                            style={{ width: '100px' }}
                          />
                        </div>
                        <div>
                          <ButtonMaterial
                            disabled={validateButtonReset}
                            handleSubmit={handleReset}
                            teksButton="Reset"
                            size="middle"
                            shape="round"
                            type="primary"
                            style={
                              validateButtonReset
                                ? {
                                    float: 'right',
                                    width: '100px',
                                  }
                                : {
                                    float: 'right',
                                    background: '#FF5858',
                                    borderColor: '#FF5858',
                                    colorFont: '#FFFFFF',
                                    width: '100px',
                                  }
                            }
                          />
                        </div>
                      </Row>
                    </div>
                  );
                }}
              </Form>
            }
          />
        }
      />
    </div>
  );
}
