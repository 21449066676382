// DEV CHANGES ON 10 DESEMBER 2024

import React from 'react';
import { Collapse, Icon, Table } from 'antd';
import ButtonMaterial from 'materials/ButtonMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import ModalConfirmation from 'components/ModalConfirmation';
import { DataTableResProps } from 'interface/general';
import { openNewTab } from 'lib/util';
const { Panel } = Collapse;

type CollapseListWaybilManifestProps = {
  isDetail?: boolean;
  selectedId?: number | null;
  dataTableScan: DataTableResProps;
  mutateDeleteWaybil?: (value: any) => void;
  isLoadingTableScan?: boolean;
  isLoadingDelete?: boolean;
  selectedWaybil?: string | null;
  setSelectedWaybiil?: (value: string | null) => void;
  setPage?: (value: number) => void;
};

export default function CollapseListWaybilManifest({
  isDetail = false,
  selectedId,
  dataTableScan,
  mutateDeleteWaybil = () => undefined,
  isLoadingTableScan = false,
  isLoadingDelete = false,
  selectedWaybil,
  setSelectedWaybiil = () => undefined,
  setPage = () => undefined,
}: CollapseListWaybilManifestProps) {
  const pagePagination = dataTableScan?.page + 1 || 0;
  const totalPagination = dataTableScan?.totalPages * 5 || 0;
  const looppage = (pagePagination - 1) * 5 + 1;

  const columnsTable = [
    {
      title: 'No',
      render: (text: any, record: any, index: any) => (
        <span>
          <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
        </span>
      ),
    },
    {
      title: 'Waybill',
      dataIndex: 'waybill',
    },
    {
      title: 'Produk',
      dataIndex: 'customerProduct',
    },
    {
      title: 'Barcode',
      dataIndex: 'barcode',
      maxWidth: 500,
    },
    {
      title: 'Nama',
      dataIndex: 'shipToName',
    },
    ...(!isDetail
      ? [
          {
            title: 'Aksi',
            render: record => (
              <div style={{ display: 'flex', gap: 8 }}>
                <ButtonMaterial
                  icon="eye"
                  type="download"
                  style={{ background: '#874FFF', color: 'white' }}
                  handleSubmit={() =>
                    openNewTab('/delivery/status?' + record.waybill)
                  }
                />
                <ButtonMaterial
                  icon="delete"
                  type="danger"
                  handleSubmit={() => setSelectedWaybiil(record?.id)}
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <Icon type="caret-right" rotate={isActive ? 90 : 0} />
      )}
    >
      <Panel
        header={
          <p
            style={{
              margin: 0,
              color: '#31E116',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            {`Total Waybill (${dataTableScan?.totalDatas || 0})`}
          </p>
        }
        key="1"
        style={{
          borderRadius: 0,
          padding: 0,
          border: 0,
        }}
      >
        <SpinMaterial
          spinning={isLoadingTableScan || isLoadingDelete}
          size={'large'}
          content={
            <Table
              columns={columnsTable}
              bordered
              dataSource={dataTableScan?.data || []}
              pagination={{
                onChange: page => {
                  setPage(page - 1);
                },
                pageSize: 5,
                total: totalPagination,
                current: pagePagination === 0 ? 1 : pagePagination,
              }}
              scroll={{ x: 'auto' }}
            />
          }
        />
      </Panel>
      <ModalConfirmation
        title="Apakah anda yakin?"
        description="Waybill akan dihapus dari entri manifest brankas"
        visibleModal={!!selectedWaybil}
        onConfirm={() => setSelectedWaybiil(null)}
        onCancel={() => mutateDeleteWaybil(selectedWaybil)}
        centered
        cancelText="YA"
        okeText="TIDAK"
      />
    </Collapse>
  );
}
