// item status =
// delivery stat = 106 -> sedang proses Pickup
const EnumHeaderTrackingDelivery = (i: any) => {
  const position = i.position > 1 ? ` (Ke-${i.position})` : ``;
  if (i.itemStatus === 'Uploaded') {
    return 'Upload Data CSV';
  } else if (i.itemStatus === 'ScanBarcode') {
    return 'Paket Di Scan Sesuai Kurir (Posko)';
  } else if (i.itemStatus === 'WaybillPrinted') {
    return 'Manifest Tercetak';
  } else if (i.itemStatus === 'ScanBranch') {
    return 'Paket Sampai Di Posko';
  } else if (i.itemStatus === 'ScanCourier') {
    return 'Paket Di Scan Sesuai Kurir (Pusat)';
  } else if (i.itemStatus === 'DownloadCourier') {
    return 'Kurir Mendownload Data Pengiriman' + position;
  } else if (i.itemStatus === 'CourierDelivering') {
    return 'Pengiriman Oleh Kurir' + position;
  } else if (i.itemStatus === 'WrongRoute') {
    return 'Kesalahan Wilayah Pengiriman';
  } else if (i.itemStatus === 'Received') {
    if (
      i.deliveryStatus === 'Return' ||
      i.deliveryStatus === 'Return3x' ||
      i.deliveryStatus === 'OnProcces'
    ) {
      return (
        'Paket Dikembalikan Ke Posko' +
        (i.position > 1 ? ' (ke-' + i.position + ')' : '')
      );
    } else {
      return (
        'Paket Telah Sampai Di Tujuan' +
        (i.position > 1 ? ' (ke-' + i.position + ')' : '')
      );
    }
  } else if (i.itemStatus === 'Resend') {
    return 'Kirim Ulang Paket' + position;
  } else if (i.itemStatus === 'PrintManifestCustomer') {
    return 'Download Manifest Pelanggan';
  } else if (i.itemStatus === 'UploadPickup') {
    return 'Terima Permintaan Pick-up';
  } else if (i.itemStatus === 'AssignPickup') {
    return 'Kurir Pick-up telah ditetapkan';
  } else if (i.itemStatus === 'SuccessPickup') {
    return 'Selesai Pick-up';
  } else if (i.itemStatus === 'UpdatedManual') {
    if (
      i.description === 'Ubah dikembalikan menjadi diterima' ||
      i.description === 'Ubah diterima menjadi dikembalikan'
    ) {
      return 'Proses Manual Scan Selesai';
    }
    return 'Paket Telah Sampai Di Tujuan' + position;
  } else if (i.itemStatus === 'Emergency') {
    return 'Proses Manual Emergency Tanggal Selesai';
  } else if (i.itemStatus === 'ScanBagging') {
    return 'Diteruskan ke Posko';
  } else if (i.itemStatus === 'ReceivedBaggingScan') {
    return 'Diterima oleh Posko';
  }
  return null;
};
const EnumContenTrackingDelivery = (itemStatus: any) => {
  const courier = `${itemStatus.courierName} (${itemStatus.courierCode})`;
  const user = `${itemStatus.fullNameActor} (${itemStatus.codeActor})`;
  if (itemStatus.itemStatus === 'Uploaded') {
    return 'Data di upload oleh admin';
  } else if (itemStatus.itemStatus === 'ScanBarcode') {
    return 'Paket dikirim oleh ' + courier;
  } else if (itemStatus.itemStatus === 'WaybillPrinted') {
    return 'Manifest dicetak oleh admin';
  } else if (itemStatus.itemStatus === 'ScanBranch') {
    return `Paket telah di scan di posko ${itemStatus.branchName}`;
  } else if (itemStatus.itemStatus === 'ScanCourier') {
    return 'Paket dikirim oleh ' + courier;
  } else if (itemStatus.itemStatus === 'DownloadCourier') {
    return 'Data telah di download oleh ' + courier;
  } else if (itemStatus.itemStatus === 'CourierDelivering') {
    return 'Paket sedang dikirim oleh' + courier;
  } else if (itemStatus.itemStatus === 'WrongRoute') {
    return `Paket yang dikirim tidak sesuai dengan wilayah posko ${itemStatus.branchName}`;
  } else if (itemStatus.itemStatus === 'Received') {
    if (itemStatus.deliveryStatus === 'Success') {
      return `Paket diterima oleh ${itemStatus.receiverName} (${itemStatus.receiverTitle})`;
    } else if (itemStatus.deliveryStatus === 'OnProcces') {
      return `Paket dikembalikan karena ${itemStatus.returnedReason}`;
    } else if (
      itemStatus.deliveryStatus === 'Return' ||
      itemStatus.deliveryStatus === 'Return3x'
    ) {
      return `Paket dikembalikan karena ${itemStatus.returnedReason}`;
    }
  } else if (itemStatus.itemStatus === 'Resend') {
    return `Paket dikirim ulang oleh ${courier}, pengiriman yang ke-${itemStatus.position}`;
  } else if (itemStatus.itemStatus === 'UploadPickup') {
    return `Data permintaan Pick-up telah diterima dari ${user}`;
  } else if (itemStatus.itemStatus === 'AssignPickup') {
    return `Paket akan di Pick-up oleh ${courier}`;
  } else if (itemStatus.itemStatus === 'SuccessPickup') {
    return `Paket sudah di Pickup oleh ${courier}`;
  } else if (itemStatus.itemStatus === 'PrintManifestCustomer') {
    return itemStatus.description;
  } else if (itemStatus.itemStatus === 'Emergency') {
    return (
      itemStatus.description +
      ` oleh ${itemStatus.fullNameActor}  (${itemStatus.codeActor})`
    );
  } else if (itemStatus.itemStatus === 'UpdatedManual') {
    if (
      itemStatus.description === 'Ubah dikembalikan menjadi diterima' ||
      itemStatus.description === 'Ubah diterima menjadi dikembalikan'
    ) {
      return (
        itemStatus.description +
        ` oleh ${itemStatus.fullNameActor}  (${itemStatus.codeActor})`
      );
    }

    if (itemStatus.deliveryStatus === 'Success') {
      return `Paket diterima oleh ${itemStatus.receiverName} (${itemStatus.receiverTitle})`;
    } else if (itemStatus.deliveryStatus === 'OnProcces') {
      return `Paket dikembalikan karena ${itemStatus.returnedReason}`;
    } else if (
      itemStatus.deliveryStatus === 'Return' ||
      itemStatus.deliveryStatus === 'Return3x'
    ) {
      return `Paket dikembalikan karena ${itemStatus.returnedReason}`;
    }
  } else if (itemStatus.itemStatus === 'ScanBagging') {
    return `Paket diteruskan Dari Transit ${itemStatus?.originalBranch} ke Pokso ${itemStatus?.destinationBranch}`;
  } else if (itemStatus.itemStatus === 'ReceivedBaggingScan') {
    return `Paket diterima oleh Pokso ${itemStatus.destinationBranch}`;
  }
  return null;
};
export { EnumHeaderTrackingDelivery, EnumContenTrackingDelivery };
