// DEV CHANGES ON 10 DESEMBER 2024

import { Col, Row, Select } from 'antd';

import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import { districtCode } from 'lib/constants';
import SelectField from 'components/SelectField';
import { Field, Form } from 'react-final-form';
import InputTextField from 'components/InputTextField';
export interface IProps {
  defaultBranchId: string;
  formRef: any;
  handleReset: () => void;
  handleSubmit: (values: any) => void;
  isLoading?: boolean;
  listBranch: { id: string; name: string }[];
  listCourier: { id: string; name: string }[];
  responseType: 'success' | 'notFound' | 'error' | undefined;
  selectedCourierCode: string | null;
  selectedWaybill: string | null;
  showResponseStatus: boolean;
}
export default function AssigningCourierComponent(props: IProps) {
  const {
    defaultBranchId,
    handleReset,
    handleSubmit,
    isLoading = false,
    listBranch,
    listCourier,
    responseType,
    selectedCourierCode,
    selectedWaybill,
    showResponseStatus,
  } = props;
  const { formRef } = props;
  const initial = React.useMemo(
    () => ({ branchId: defaultBranchId, typeScan: 'barcode' }),
    [defaultBranchId]
  );

  return (
    <div style={{ padding: '35px' }}>
      <SpinMaterial
        spinning={isLoading}
        style={{}}
        size={'large'}
        content={
          <React.Fragment>
            <CardMaterial
              extra={''}
              style={{ borderRadius: '8px' }}
              title={
                <div style={{ color: '#11BEFF' }}>
                  <span>
                    <b>SCAN KURIR</b>
                  </span>
                </div>
              }
            >
              <Form
                onSubmit={() => {}}
                initialValues={initial}
                validate={values => {
                  const errors = {} as any;

                  if (!values.branchId) {
                    errors.branchId = 'Posko wajib diisi';
                  }
                  if (!values.deliveryDistrictType) {
                    errors.deliveryDistrictType = 'Wilayah wajib diisi';
                  }
                  if (!values.courierId) {
                    errors.courierId = 'Kurir wajib diisi';
                  }
                  if (!values.waybillOrBarcode) {
                    errors.waybillOrBarcode =
                      'Barcode / Waybill tidak boleh kosong';
                  }
                  return errors;
                }}
              >
                {(formProps: any) => {
                  formRef.current = formProps;
                  const { values, invalid } = formProps;

                  const disabledResetButton = !(
                    values.courierId ||
                    values.deliveryDistrictType ||
                    values.waybillOrBarcode
                  );

                  return (
                    <div>
                      <Row gutter={24}>
                        <Col span={12}>
                          <Field name="branchId">
                            {({ input }) => (
                              <SelectField
                                label="Posko"
                                placeholder="Pilih Posko"
                                data={listBranch}
                                value={input.value}
                                validate
                                onChange={value => {
                                  input.onChange(value);
                                }}
                                onBlur={value => {
                                  input.onBlur(value);
                                }}
                                disabled
                              />
                            )}
                          </Field>
                        </Col>

                        <Col span={12}>
                          <Field name="deliveryDistrictType">
                            {({ input, meta }) => (
                              <SelectField
                                label="Wilayah"
                                placeholder="Pilih Wilayah"
                                data={districtCode}
                                value={input.value}
                                validate
                                onChange={value => input.onChange(value)}
                                onBlur={value => input.onBlur(value)}
                                validateStatus={
                                  meta.error && meta.touched
                                    ? 'error'
                                    : 'validating'
                                }
                                errorMessage={
                                  meta.error && meta.touched && meta.error
                                }
                              />
                            )}
                          </Field>
                        </Col>
                      </Row>
                      <Row gutter={24} style={{ marginTop: '24px' }}>
                        <Col span={12}>
                          <Field name="courierId">
                            {({ input, meta }) => (
                              <SelectField
                                placeholder="Pilih Kurir"
                                label="Kurir"
                                disabled={false}
                                style={{ width: '100%' }}
                                value={input.value}
                                onBlur={input.onBlur}
                                onChange={value => input.onChange(value)}
                                data={listCourier}
                                validate
                                validateStatus={
                                  meta.error && meta.touched
                                    ? 'error'
                                    : 'validating'
                                }
                                errorMessage={
                                  meta.error && meta.touched && meta.error
                                }
                              />
                            )}
                          </Field>
                        </Col>

                        <Col span={12}>
                          <Field name="waybillOrBarcode">
                            {({ input, meta }) => (
                              <InputTextField
                                label="Scan"
                                placeholder={
                                  'Masukkan ' +
                                  (values.typeScan === 'waybill'
                                    ? 'Waybill'
                                    : 'Barcode')
                                }
                                value={input.value}
                                onChange={value =>
                                  input.onChange(value.toUpperCase())
                                }
                                onBlur={value => input.onBlur(value)}
                                onPressEnter={() => handleSubmit(values)}
                                error={meta.error && meta.touched && meta.error}
                                width="100%"
                                disabled={
                                  !values.courierId ||
                                  !values.deliveryDistrictType
                                }
                                addonBefore={
                                  <Field name="typeScan">
                                    {({ input }) => (
                                      <Select
                                        onChange={value =>
                                          input.onChange(value)
                                        }
                                        value={input.value}
                                      >
                                        <Select.Option value="barcode">
                                          Barcode
                                        </Select.Option>
                                        <Select.Option value="waybill">
                                          Waybill
                                        </Select.Option>
                                      </Select>
                                    )}
                                  </Field>
                                }
                              />
                            )}
                          </Field>
                        </Col>
                      </Row>
                      {showResponseStatus && (
                        <Row
                          style={{
                            float: 'left',
                            marginTop: '28px',
                            width: '100%',
                          }}
                        >
                          <span>
                            <b>Status</b>
                          </span>
                          <p>
                            {responseType === 'success' ? (
                              <b>
                                <span style={{ color: '#11BEFF' }}>
                                  {selectedWaybill}
                                </span>{' '}
                                <span style={{ color: '#31E116' }}>
                                  Dikirim oleh {selectedCourierCode}
                                </span>
                              </b>
                            ) : responseType === 'notFound' ? (
                              <b>
                                <span style={{ color: '#11BEFF' }}>
                                  {selectedWaybill}
                                </span>{' '}
                                <span style={{ color: 'red' }}>
                                  Tidak ditemukan
                                </span>
                              </b>
                            ) : responseType === 'error' ? (
                              <b>
                                <span style={{ color: '#11BEFF' }}>
                                  {selectedWaybill}
                                </span>{' '}
                                <span style={{ color: 'red' }}>Gagal Scan</span>
                              </b>
                            ) : null}
                          </p>
                        </Row>
                      )}
                      <Row
                        style={{
                          marginTop: '24px',
                          float: 'right',
                          gap: '10px',
                        }}
                      >
                        <ButtonMaterial
                          disabled={invalid}
                          handleSubmit={() => handleSubmit(values)}
                          teksButton={<b>Simpan</b>}
                          size={'middle'}
                          shape={'round'}
                          className={''}
                          type={'primary'}
                          icon={''}
                          style={{
                            height: '35px',
                            width: '100px',
                            marginRight: '24px',
                          }}
                        />
                        <ButtonMaterial
                          disabled={disabledResetButton}
                          handleSubmit={handleReset}
                          teksButton={<b>Reset</b>}
                          size={'middle'}
                          shape={'round'}
                          className={''}
                          type={'primary'}
                          icon={''}
                          style={
                            disabledResetButton === false
                              ? {
                                  background: '#FF5858',
                                  borderColor: '#FF5858',
                                  colorFont: '#FFFFFF',
                                  height: '35px',
                                  width: '100px',
                                }
                              : {
                                  height: '35px',
                                  width: '100px',
                                }
                          }
                        />
                      </Row>
                    </div>
                  );
                }}
              </Form>
            </CardMaterial>
          </React.Fragment>
        }
      />
    </div>
  );
}
