import * as ActionCustomerFiture from '../../../core/action/Customer/ActionCustomerFiture';
import * as ActionNavigation from '../../../core/action/Router/ActionRouter';
import * as toggle from '../../../core/action/toggle';

import React from 'react';
import { bindActionCreators, compose } from 'redux';

import CustomerConfigFitureComponent from '../../../components/Content/Customer/CustomerConfigFitureComponent';
import { connect } from 'react-redux';
import history from '../../../core/utils/History';

interface IProps {
  GetSmsDelivery: any;
  GetSmsDelivered: any;
  GetSenderAlias: any;
  GetSmsDeliveryBody: any;
  GetSmsDeliveredBody: any;
  GetIdFiture: any;
  GetTypeFiture: any;
  GetCall: any;
  GetCustomerName: any;
  isLoading: any;
  actionCustomerFiture: any;
  actionNavigation: any;
  IsRadius: boolean;
  IsRequestPhone: boolean;
  IsHistory: boolean;
  RadiusMeters: number;
  isMonthFilter: boolean;
  searchMonthFilter: number;
}

interface IState {
  checkBoxSmsDelivery: any;
  checkBoxSmsDelivered: any;
  checkBoxTelepon: any;
  validateSenderAlias: any;
  validateSmsDeliveryBody: any;
  validateSmsDeliveredBody: any;
}

const CustomerConfigCustomFitureContainer = (props: IProps) => {
  const {
    actionCustomerFiture,
    actionNavigation,
    GetCall,
    GetCustomerName,
    GetSenderAlias,
    GetSmsDelivered,
    GetSmsDeliveredBody,
    GetSmsDelivery,
    GetSmsDeliveryBody,
    GetTypeFiture,
    IsHistory,
    isLoading,
    isMonthFilter,
    IsRadius,
    IsRequestPhone,
    RadiusMeters,
    searchMonthFilter,
  } = props;
  const [state, setState] = React.useState<IState>({
    checkBoxSmsDelivery: null,
    checkBoxSmsDelivered: null,
    checkBoxTelepon: null,
    validateSenderAlias: null,
    validateSmsDeliveryBody: null,
    validateSmsDeliveredBody: null,
  });

  const handleCheckboxSmsDelivery = (event: any) => {
    actionCustomerFiture.changeSmsDelivery(event.target.checked);
    if (event.target.checked === true) {
      setState({
        ...state,
        validateSmsDeliveryBody: 'error',
      });
    } else {
      setState({
        ...state,
        validateSmsDeliveryBody: '',
      });
    }
    actionCustomerFiture.changeClearSmsDeliveryBody();
  };

  const handleCheckboxSmsDelivered = (event: any) => {
    actionCustomerFiture.changeSmsDelivered(event.target.checked);
    if (event.target.checked === true) {
      setState({
        ...state,
        validateSmsDeliveredBody: 'error',
      });
    } else {
      setState({
        ...state,
        validateSmsDeliveredBody: '',
      });
    }
    actionCustomerFiture.changeClearSmsDeliveredBody();
  };

  const handleCheckboxTelepon = (event: any) => {
    actionCustomerFiture.changeCall(event.target.checked);
  };

  const handleCheckboxIsRadius = (event: any) => {
    actionCustomerFiture.changeIsRadius(event.target.checked);
  };

  const handleCheckboxIsRequestPhone = (event: any) => {
    actionCustomerFiture.changeIsRequestPhone(event.target.checked);
  };

  const handleCheckboxIsHistory = (event: any) => {
    actionCustomerFiture.changeIsHistory(event.target.checked);
  };

  const handleChangeRadiusMeters = (value: number) => {
    actionCustomerFiture.changeRadiusMeters(value);
  };
  const handleChangeIsMonthFilter = (event: any) => {
    actionCustomerFiture.changeIsMonthFilter(event.target.checked);
    if (!event.target.checked) {
      actionCustomerFiture.changeSearchMonthFilter(0);
    }
  };
  const handleChangeSearchMonthFilter = (value: number) => {
    actionCustomerFiture.changeSearchMonthFilter(value);
  };

  const handleBack = () => {
    actionCustomerFiture.changeClearCustomerFiture();
    history.push('/customer');
    actionNavigation.setRouter('/customer');
  };

  const handleSenderAlias = (event: any) => {
    if (event.target.value === '') {
      setState({
        ...state,
        validateSenderAlias: 'error',
      });
    } else {
      setState({
        ...state,
        validateSenderAlias: '',
      });
    }
    actionCustomerFiture.changeSenderAlias(event.target.value);
  };

  const handleSmsDeliveryBody = (event: any) => {
    actionCustomerFiture.changeSmsDeliveryBody(event.target.value);
    if (event.target.value === '') {
      setState({
        ...state,
        validateSmsDeliveryBody: 'error',
      });
    } else {
      setState({
        ...state,
        validateSmsDeliveryBody: '',
      });
    }
  };
  const handleSmsDeliveredBody = (event: any) => {
    actionCustomerFiture.changeSmsDeliveredBody(event.target.value);
    if (event.target.value === '') {
      setState({
        ...state,
        validateSmsDeliveredBody: 'error',
      });
    } else {
      setState({
        ...state,
        validateSmsDeliveredBody: '',
      });
    }
  };
  const handleSubmitFiture = () => {
    if (GetTypeFiture === 'Add') {
      actionCustomerFiture.changeAddCustomerFiture();
    } else {
      actionCustomerFiture.changeEditCustomerFiture();
    }
  };
  const handleEditFiture = () => {
    actionCustomerFiture.changeTypeFiture('Edit');
  };
  const handleCancel = () => {
    actionCustomerFiture.changeDetailCustomerFiture();
    setState({
      ...state,
      validateSenderAlias: null,
      validateSmsDeliveryBody: null,
      validateSmsDeliveredBody: null,
    });
  };

  return (
    <div>
      <CustomerConfigFitureComponent
        ValidateButton={
          (GetSmsDelivery === true && GetSmsDeliveryBody === '') ||
          (GetSmsDelivered === true && GetSmsDeliveredBody === '') ||
          GetSenderAlias === '' ||
          GetTypeFiture === 'Detail'
            ? true
            : false
        }
        handleCheckboxSmsDelivery={handleCheckboxSmsDelivery}
        handleCheckboxSmsDelivered={handleCheckboxSmsDelivered}
        handleCheckboxTelepon={handleCheckboxTelepon}
        putSenderAlias={handleSenderAlias}
        putSmsDeliveryBody={handleSmsDeliveryBody}
        putSmsDeliveredBody={handleSmsDeliveredBody}
        messageDeliverySms={
          GetTypeFiture === 'Detail'
            ? true
            : GetTypeFiture === 'Edit' && GetSmsDelivery === true
            ? false
            : GetTypeFiture === 'Add' && GetSmsDelivery === true
            ? false
            : true
        }
        messageDeliveredSms={
          GetTypeFiture === 'Detail'
            ? true
            : GetTypeFiture === 'Edit' && GetSmsDelivered === true
            ? false
            : GetTypeFiture === 'Add' && GetSmsDelivered === true
            ? false
            : true
        }
        senderAliasStatus={
          GetTypeFiture === 'Edit' || GetTypeFiture === 'Add' ? false : true
        }
        GetSmsDelivery={GetSmsDelivery}
        GetSmsDelivered={GetSmsDelivered}
        GetSenderAlias={GetSenderAlias}
        GetSmsDeliveryBody={GetSmsDeliveryBody}
        GetSmsDeliveredBody={GetSmsDeliveredBody}
        GetCall={GetCall}
        IsRadius={IsRadius}
        IsRequestPhone={IsRequestPhone}
        IsHistory={IsHistory}
        RadiusMeters={RadiusMeters}
        // CONFIG CUSTOMER
        GetCustomerName={GetCustomerName}
        typeButton={GetTypeFiture}
        handleSubmitFiture={handleSubmitFiture}
        handleEditFiture={handleEditFiture}
        validateSenderAlias={
          state.validateSenderAlias === 'error' ? 'error' : ''
        }
        helpSenderAlias={
          state.validateSenderAlias === 'error'
            ? 'Nama masking tidak boleh kosong!'
            : ''
        }
        validateSmsDeliveryBody={
          state.validateSmsDeliveryBody === 'error' && GetSmsDelivery === false
            ? ''
            : state.validateSmsDeliveryBody === '' && GetSmsDelivery === true
            ? ''
            : state.validateSmsDeliveryBody === 'error' &&
              GetSmsDelivery === true
            ? 'error'
            : ''
        }
        helpSmsDeliveryBody={
          state.validateSmsDeliveryBody === 'error' && GetSmsDelivery === false
            ? ''
            : state.validateSmsDeliveryBody === '' && GetSmsDelivery === true
            ? ''
            : state.validateSmsDeliveryBody === 'error' &&
              GetSmsDelivery === true
            ? 'Pesan pengiriman tidak boleh kosong'
            : ''
        }
        validateSmsDeliveredBody={
          state.validateSmsDeliveredBody === 'error' &&
          GetSmsDelivered === false
            ? ''
            : state.validateSmsDeliveredBody === '' && GetSmsDelivered === true
            ? ''
            : state.validateSmsDeliveredBody === 'error' &&
              GetSmsDelivered === true
            ? 'error'
            : ''
        }
        helpSmsDeliveredBody={
          state.validateSmsDeliveredBody === 'error' &&
          GetSmsDelivered === false
            ? ''
            : state.validateSmsDeliveredBody === '' && GetSmsDelivered === true
            ? ''
            : state.validateSmsDeliveredBody === 'error' &&
              GetSmsDelivered === true
            ? 'Pesan pengiriman tidak boleh kosong'
            : ''
        }
        handleBack={handleBack}
        handleCancel={handleCancel}
        isLoading={isLoading}
        handleChangeRadiusMeters={handleChangeRadiusMeters}
        handleCheckboxIsRadius={handleCheckboxIsRadius}
        handleCheckboxIsRequestPhone={handleCheckboxIsRequestPhone}
        handleCheckboxIsHistory={handleCheckboxIsHistory}
        isMonthFilter={isMonthFilter}
        searchMonthFilter={searchMonthFilter}
        handleChangeIsMonthFilter={handleChangeIsMonthFilter}
        handleChangeSearchMonthFilter={handleChangeSearchMonthFilter}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  GetTypeFiture: state.Customer.CustomerFiture.TypeFiture,
  GetDisableFormSmsDeliveryBody:
    state.Customer.CustomerFiture.DisableFormSmsDeliveryBody,
  GetIdFiture: state.Customer.CustomerFiture.IdFiture,
  GetSmsDelivery: state.Customer.CustomerFiture.SmsDelivery,
  GetSmsDelivered: state.Customer.CustomerFiture.SmsDelivered,
  GetCall: state.Customer.CustomerFiture.Call,
  IsRadius: state.Customer.CustomerFiture.IsRadius,
  IsRequestPhone: state.Customer.CustomerFiture.IsRequestPhone,
  IsHistory: state.Customer.CustomerFiture.IsHistory,
  RadiusMeters: state.Customer.CustomerFiture.RadiusMeters,
  GetSenderAlias: state.Customer.CustomerFiture.SenderAlias,
  GetSmsDeliveryBody: state.Customer.CustomerFiture.SmsDeliveryBody,
  GetSmsDeliveredBody: state.Customer.CustomerFiture.SmsDeliveredBody,
  GetCustomerName: state.Customer.DetailCustomer.CustomerName,
  isLoading: state.Toggle.isLoading,
  isMonthFilter: state.Customer.CustomerFiture.isMonthFilter,
  searchMonthFilter: state.Customer.CustomerFiture.searchMonthFilter,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
  actionCustomerFiture: bindActionCreators(ActionCustomerFiture, dispatch),
  actionNavigation: bindActionCreators(ActionNavigation, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CustomerConfigCustomFitureContainer);
