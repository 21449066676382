import { Col, Icon, Row, Select, Table } from 'antd';
import Button from 'components/Button';
import { customExpandIcon } from 'components/ExpandRowItemCard';
import InputDatePicker from 'components/InputDatePicker';
import InputTextField from 'components/InputTextField';
import SelectField from 'components/SelectField';
import CardMaterial from 'materials/CardMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import moment from 'moment-timezone';
import React from 'react';
import { Field, Form } from 'react-final-form';

export default function SortDocumentComponent(props: any) {
  const {
    columnData,
    currentPage,
    dataSortedDocument = [],
    handleSubmitScanDocument,
    isFetchingCountingSortedDocument,
    isFetchingCustomers,
    isFetchingProducts,
    isFetchingSortedDocument,
    isLoadingMutation,
    isResponseSuccess,
    listCustomer = [],
    listProduct = [],
    handleRefresh,
    selectedWaybill,
    setCurrentPage,
    setModalDetailUnscanDocument,
    setSelectedCustomer,
    setSelectedCustomerProduct,
    setSelectedPeriodeDelivery,
    setSelectedTypePeriode,
    setSelectedTypeScan,
    totalDatas,
    totalUnscan,
    totalUserScan,
    totalWaybill,
  } = props;

  const initialValues = React.useMemo(
    () => ({
      typePeriode: 'Cycle',
      periodeDelivery: null,
      customer: null,
      customerProduct: null,
      typeScan: 'barcode',
      scan: '',
    }),
    []
  );

  const ExpandRowItemCard = ({
    label,
    datas,
  }: {
    label: string;
    datas: any[];
  }) => {
    const datasGroupByUser = datas.reduce((acc, current) => {
      const found = acc.find(item => item.userScan === current.userScan);
      if (found) {
        found.datas.push(current);
      } else {
        acc.push({ userScan: current.userScan, datas: [current] });
      }
      return acc;
    }, [] as { userScan: string; datas: typeof datas }[]);

    return (
      <div style={{ display: 'flex', gap: 40 }}>
        <div>
          <p style={{ margin: 0 }}>
            <span>{label} </span>
          </p>
        </div>
        <div style={{ display: 'flex', gap: 8 }}>
          :
          <div
            style={{
              display: 'flex',
              gap: 12,
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {datasGroupByUser &&
              datasGroupByUser.map((item, index) => (
                <div
                  style={{
                    padding: '4px 8px',
                    background: '#FAFAFA',
                    borderRadius: 4,
                    border: '1px solid #E0E0E0',
                  }}
                >
                  <p style={{ margin: 0 }} key={index}>
                    <span style={{ fontWeight: 500 }}>{item?.userScan}</span> :{' '}
                    <span style={{ fontWeight: 'bold', color: '#00AB44' }}>
                      {item.datas.length}
                    </span>
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <SpinMaterial spinning={isLoadingMutation} size="large">
        <CardMaterial
          style={{ borderRadius: '8px' }}
          title={
            <span style={{ color: '#11beff' }}>
              <b>Tanda Terima</b>
            </span>
          }
        >
          <Form
            initialValues={initialValues}
            validate={values => {
              const errors = {} as any;

              if (!values.periodeDelivery) {
                errors.periodeDelivery = 'Tanggal wajib dipilih';
              }

              if (!values.customer) {
                errors.customer = 'Pelanggan wajib dipilih';
              }
              if (values.customer) {
                if (
                  !values.customerProduct ||
                  values.customerProduct.length === 0
                ) {
                  errors.customerProduct = 'Produk wajib dipilih';
                }
              }

              return errors;
            }}
            onSubmit={() => {}}
          >
            {(formProps: any) => {
              const { values, subscribedField, form } = formProps;
              return (
                <Row>
                  <Row gutter={[32, 24]}>
                    <Col span={12}>
                      <Field name="customer" subscription={subscribedField}>
                        {({ input, meta }) => (
                          <SelectField
                            label="Pelanggan"
                            placeholder="Pilih Pelanggan"
                            data={listCustomer}
                            value={input.value}
                            onBlur={input.onBlur}
                            onChange={value => {
                              setSelectedCustomer(value);
                              form.change('customerProduct', null);
                              return input.onChange(value);
                            }}
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            loading={isFetchingCustomers}
                            validate
                          />
                        )}
                      </Field>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                      }}
                    >
                      <Field
                        name="periodeDelivery"
                        subscription={subscribedField}
                      >
                        {({ input, meta }) => (
                          <InputDatePicker
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            label="Periode Pengiriman"
                            format="DD-MM-YYYY"
                            error={meta.error && meta.touched && meta.error}
                            onBlur={input.onBlur}
                            value={input.value}
                            onChange={value => {
                              setSelectedPeriodeDelivery(
                                moment(value).format('YYYYMMDD')
                              );
                              return input.onChange(value);
                            }}
                            placeholder="Pilih Tanggal"
                            addonBefore={
                              <Field
                                name="typePeriode"
                                subscription={subscribedField}
                              >
                                {({ input }) => (
                                  <SelectField
                                    value={input.value || 'Cycle'}
                                    data={[
                                      { id: 'Cycle', name: 'Cycle' },
                                      { id: 'Process', name: 'Upload' },
                                    ]}
                                    style={{ width: 100 }}
                                    onChange={value => {
                                      setSelectedTypePeriode(value);
                                      return input.onChange(value);
                                    }}
                                  />
                                )}
                              </Field>
                            }
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row gutter={[32, 24]}>
                    <Col
                      span={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                      }}
                    >
                      <Field name="customerProduct">
                        {({ input, meta }) => (
                          <SelectField
                            label="Produk Pelanggan"
                            placeholder="Pilih Produk Pelanggan"
                            mode="multiple"
                            allowClear
                            data={[
                              ...(Array.isArray(listProduct) &&
                              listProduct?.length === input.value.length
                                ? []
                                : listProduct?.length !== 0
                                ? [
                                    {
                                      key: 'SetAllProduct',
                                      id: 'SetAllProduct',
                                      name: 'Pilih Semua Produk',
                                    },
                                  ]
                                : []),
                              ...(Array.isArray(listProduct)
                                ? listProduct
                                : []),
                            ]}
                            value={input.value}
                            onBlur={input.onBlur}
                            onChange={value => {
                              if (value && value.includes('SetAllProduct')) {
                                const allIds = listProduct?.map(
                                  (data: any) => data.id
                                );
                                setSelectedCustomerProduct(allIds);
                                return input.onChange(allIds);
                              } else {
                                setSelectedCustomerProduct(value);
                                return input.onChange(value);
                              }
                            }}
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            disabled={!values.customer}
                            loading={isFetchingProducts}
                            validate
                          />
                        )}
                      </Field>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                      }}
                    >
                      <Field name="scan">
                        {({ input, meta }) => (
                          <InputTextField
                            label="Scan"
                            placeholder={
                              'Masukkan ' +
                              (values.typeScan === 'waybill'
                                ? 'Waybill'
                                : 'Barcode')
                            }
                            value={input.value}
                            onChange={value => {
                              setSelectedTypeScan(value.toUpperCase());
                              return input.onChange(value.toUpperCase());
                            }}
                            onBlur={value => {
                              input.onBlur(value);
                            }}
                            onPressEnter={() =>
                              handleSubmitScanDocument(values)
                            }
                            error={meta.error && meta.touched && meta.error}
                            width="100%"
                            addonBefore={
                              <Field name="typeScan">
                                {({ input }) => (
                                  <Select
                                    onChange={value => input.onChange(value)}
                                    value={input.value}
                                  >
                                    <Select.Option value="barcode">
                                      Barcode
                                    </Select.Option>
                                    <Select.Option value="waybill">
                                      Waybill
                                    </Select.Option>
                                  </Select>
                                )}
                              </Field>
                            }
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Col span={24} style={{ padding: '0 16px' }}>
                    <Row gutter={[32, 24]}>
                      <Col span={12}></Col>
                      <Col
                        span={12}
                        style={{
                          paddingRight: 0,
                        }}
                      >
                        <Row
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {dataSortedDocument?.length > 0 && (
                            <Col span={3} style={{ padding: '12px 0' }}>
                              <Button icon="reload" onClick={handleRefresh} />
                            </Col>
                          )}
                          <Col
                            span={dataSortedDocument?.length > 0 ? 7 : 8}
                            style={{ padding: '12px 2px', textAlign: 'center' }}
                          >
                            <p
                              style={{
                                color: '#BDBDBD',
                                fontWeight: 'bold',
                                minWidth: '120px',
                                margin: 0,
                              }}
                            >
                              Sudah di scan :{' '}
                              <b style={{ color: '#00AB44' }}>
                                {totalUserScan}
                              </b>
                            </p>
                          </Col>
                          <Col
                            span={dataSortedDocument?.length > 0 ? 7 : 8}
                            style={{ padding: '12px 2px', textAlign: 'center' }}
                          >
                            <p
                              style={{
                                color: '#11BEFF',
                                fontWeight: 'bold',
                                minWidth: '120px',
                                margin: 0,
                              }}
                            >
                              <button
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  color: '#11BEFF',
                                  cursor: 'pointer',
                                  fontWeight: 'bold',
                                }}
                                onClick={() =>
                                  setModalDetailUnscanDocument(true)
                                }
                              >
                                <span>Belum di scan : </span>
                              </button>
                              <b style={{ color: '#EA1A1A' }}>{totalUnscan}</b>
                            </p>
                          </Col>
                          <Col
                            span={dataSortedDocument?.length > 0 ? 7 : 8}
                            style={{ padding: '12px 2px', textAlign: 'center' }}
                          >
                            <p
                              style={{
                                color: '#BDBDBD',
                                fontWeight: 'bold',
                                margin: 0,
                              }}
                            >
                              Total :{' '}
                              <b style={{ color: '#424242' }}>{totalWaybill}</b>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          {isResponseSuccess && (
                            <Col
                              span={24}
                              style={{ paddingLeft: 0, color: '#00AB44' }}
                            >
                              <span>
                                <Icon
                                  type="check-circle"
                                  theme="filled"
                                  style={{ marginRight: 8 }}
                                />
                                <b>{selectedWaybill}</b> berhasil di-scan
                              </span>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            }}
          </Form>
        </CardMaterial>
      </SpinMaterial>
      <SpinMaterial
        spinning={isFetchingSortedDocument || isFetchingCountingSortedDocument}
        size="large"
      >
        <Table
          style={{ marginTop: 32 }}
          rowKey={(record: any) => record.id}
          scroll={{ x: 'auto' }}
          pagination={{
            onChange: page => setCurrentPage(page - 1),
            pageSize: 10,
            total: totalDatas,
            current: currentPage === 0 ? 1 : currentPage + 1,
          }}
          bordered={false}
          dataSource={dataSortedDocument || []}
          columns={columnData}
          footer={() => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                gap: 8,
              }}
            >
              <p style={{ margin: 0, fontWeight: 500 }}>
                Total Entri ({totalDatas || 0})
              </p>
            </div>
          )}
          expandIcon={props => customExpandIcon(props)}
          expandedRowRender={record => (
            <ExpandRowItemCard
              label="Di Scan Oleh"
              datas={record?.sortDocumentDetails || []}
            />
          )}
        />
      </SpinMaterial>
    </div>
  );
}
