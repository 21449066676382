import * as ActionCustomDataReportCustomer from '../../action/Customer/ActionCustomDataReportCustomer';
import * as ActionCustomerLoading from '../../action/Customer/ActionCustomerLoading';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_GetListColumnMasterData() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    const IdCustomCsv = state.Customer.FormCustomerConfigReport.SettingReport;
    const Column =
      state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
        .SourceColumn;
    yield put(ActionCustomerLoading.setLoadingCustomer());
    yield put(
      ActionCustomerLoading.setTypeLoadingCustomer(
        Column === 'ShipToAddress(STRING)' &&
          Column === 'AdditionalData(STRING)' &&
          Column === 'AdditionalAddress(STRING)' &&
          Column === 'ReceiverLocation(STRING)' &&
          Column === 'ReceiverLocationManual(STRING)' &&
          Column === 'Branch' &&
          Column === 'Courier'
          ? 'selectSubColumn'
          : 'selectColumnName'
      )
    );

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Setting/CustomReportData/master/${IdCustomCsv}`,
      { headers: config }
    );

    yield put(
      ActionCustomDataReportCustomer.setListSourceColumnCdrc(data.listColumn)
    );
    if (Column === 'ShipToAddress(STRING)') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(data.shipToAddress)
      );
    } else if (Column === 'AdditionalData(STRING)') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(data.additionalData)
      );
    } else if (Column === 'AdditionalAddress(STRING)') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(
          data.additionalAddress
        )
      );
    } else if (Column === 'Branch') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(data.branch)
      );
    } else if (Column === 'Courier') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(data.courier)
      );
    } else if (Column === 'CustomStatusCode(STRING)') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(
          data.customStatusCode
        )
      );
    } else if (Column === 'ReceiverLocation(STRING)') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(
          data.receiverLocation
        )
      );
    } else if (Column === 'ReceiverLocationManual(STRING)') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(
          data.receiverLocationManual
        )
      );
    }
    yield put(ActionCustomerLoading.setLoadingCustomer());
    yield put(ActionCustomerLoading.clearTypeLoadingCustomer());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
    } else {
      yield put(ActionCustomerLoading.setLoadingCustomer());
      yield put(ActionCustomerLoading.clearTypeLoadingCustomer());
      Modal.error({
        title: <p style={{ color: '#FF0000' }}>Anda belum mengatur file CSV</p>,
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>Silahkan atur file csv dahulu</p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* S_GetListColumnHistory() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };

  const Column =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .SourceColumn;
  try {
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Setting/CustomReportData/history',
      { headers: config }
    );

    yield put(
      ActionCustomDataReportCustomer.setListSourceColumnCdrc(data.listColumn)
    );
    if (Column === 'ShipToAddress(STRING)') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(data.shipToAddress)
      );
    } else if (Column === 'AdditionalData(STRING)') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(data.additionalData)
      );
    } else if (Column === 'AdditionalAddress(STRING)') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(
          data.additionalAddress
        )
      );
    } else if (Column === 'Branch') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(data.branch)
      );
    } else if (Column === 'Courier') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(data.courier)
      );
    } else if (Column === 'ReceiverLocation(STRING)') {
      yield put(
        ActionCustomDataReportCustomer.setListSubColumnCdrc(
          data.receiverLocation
        )
      );
    }
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      Modal.error({
        title: <p style={{ color: '#FF0000' }}>Anda belum mengatur file CSV</p>,
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>Silahkan atur file csv dahulu</p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_GetListColumnMasterData() {
  yield takeLatest(
    'GET_LIST_COLUMN_MASTER_CUSTOM_REPORT_CUSTOMER',
    S_GetListColumnMasterData
  );
}
export function* SM_GetListColumnHistory() {
  yield takeLatest(
    'GET_LIST_COLUMN_HISTORY_CUSTOM_REPORT_CUSTOMER',
    S_GetListColumnHistory
  );
}
