import * as ActionClearReasonDeliveryCustomer from '../../../core/action/Customer/ActionReasonStatusCustomer';
import * as ActionClearTableReasonDeliveryCustomer from '../../../core/action/Customer/ActionTableStatusDefault';
import * as ActionCustomDataReportCustomer from '../../../core/action/Customer/ActionCustomDataReportCustomer';
import * as ActionDetailCustomCsvCustomer from '../../../core/action/Customer/ActionDetailCustomCsvCustomer';
import * as ActionDetailFitureCustomer from '../../../core/action/Customer/ActionCustomerFiture';
import * as ActionFormConfigReport from '../../../core/action/Customer/ActionDetailConfigReport';
import * as ActionFormCsv from '../../../core/action/Customer/ActionSetCustomCsvCustomer';
import * as ActionLastAccess from '../../../core/action/LastAccess/index';
import * as ActionRadiobutton from '../../../core/action/Customer/ActionRadiobuttonStatus';

import React from 'react';
import { bindActionCreators, compose } from 'redux';

import IndexCustomerConfigComponent from '../../../components/Content/Customer/IndexCustomerConfigComponent';
import { connect } from 'react-redux';

interface IProps {
  actionDetailCustomCsvCustomer: any;
  actionDetailFitureCustomer: any;
  actionClearStatusDeliveryCustomer: any;
  actionClearTableReasonDeliveryCustomer: any;
  actionLastAccess: any;
  actionFormConfigReport: any;
  actionFormCsv: any;
  actionCustomDataReportCustomer: any;
  actionRadiobutton: any;
  lastAccess: any;
}
const IndexCustomerConfigContainer = (props: IProps) => {
  const {
    actionDetailFitureCustomer,
    actionClearStatusDeliveryCustomer,
    actionClearTableReasonDeliveryCustomer,
    actionLastAccess,
    actionFormConfigReport,
    actionFormCsv,
    actionCustomDataReportCustomer,
    actionRadiobutton,
    lastAccess,
  } = props;

  const handleChangeTabsCustomer = (event: any) => {
    actionClearStatusDeliveryCustomer.changeClearReasonStat();
    actionDetailFitureCustomer.changeDetailCustomerFiture();
    actionFormConfigReport.changeDetailCustomerConfigReport();
    actionClearTableReasonDeliveryCustomer.clearDefaultDataStatus();
    actionClearTableReasonDeliveryCustomer.clearCustomDataStatus();
    actionFormConfigReport.changeClearCustomerConfigReport();
    actionFormConfigReport.clearListCustomerReport();
    actionFormConfigReport.changeClearCustomerTypeFormConfigReport();
    actionCustomDataReportCustomer.clearFieldAllCustomReportCustomer();
    actionRadiobutton.clearRadiobutton();
    actionFormCsv.clearAllDataCustomCsvCustomer();

    if (event === '1') {
      actionLastAccess.changeLastAccessFiture('customReasonDelivery');
    } else if (event === '2') {
      actionLastAccess.changeLastAccessFiture('csvSetting');
    } else if (event === '3') {
      actionLastAccess.changeLastAccessFiture('voipSetting');
    } else if (event === '4') {
      actionLastAccess.changeLastAccessFiture('configReport');
    }
  };

  return (
    <div>
      <IndexCustomerConfigComponent
        handleChangeTabsCustomer={handleChangeTabsCustomer}
        lastAccess={lastAccess}
      />
    </div>
  );
};

const mapStateProps = (state: any) => ({
  lastAccess: state.LastAccess.Fiture,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionDetailCustomCsvCustomer: bindActionCreators(
    ActionDetailCustomCsvCustomer,
    dispatch
  ),
  actionDetailFitureCustomer: bindActionCreators(
    ActionDetailFitureCustomer,
    dispatch
  ),
  actionClearStatusDeliveryCustomer: bindActionCreators(
    ActionClearReasonDeliveryCustomer,
    dispatch
  ),
  actionClearTableReasonDeliveryCustomer: bindActionCreators(
    ActionClearTableReasonDeliveryCustomer,
    dispatch
  ),
  actionLastAccess: bindActionCreators(ActionLastAccess, dispatch),
  actionFormConfigReport: bindActionCreators(ActionFormConfigReport, dispatch),
  actionCustomDataReportCustomer: bindActionCreators(
    ActionCustomDataReportCustomer,
    dispatch
  ),
  actionFormCsv: bindActionCreators(ActionFormCsv, dispatch),
  actionRadiobutton: bindActionCreators(ActionRadiobutton, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(IndexCustomerConfigContainer);
