import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getListUnscanDocument } from 'services/SortDocumentServices';
import ModalDetailUnscanDocumentComponent from '../Component/ModalDetailUnscanDocumentComponent';
interface IProps {
  modalDetailUnscanDocument: boolean;
  setModalDetailUnscanDocument: (val: boolean) => void;
  selectedCustomer: any;
  selectedCustomerProduct: any;
  selectedTypePeriode: string;
  selectedPeriodeDelivery: string;
}
export default function ModalDetailUnscanDocumentContainer(props: IProps) {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentSize] = useState<number>(10);
  const {
    modalDetailUnscanDocument,
    setModalDetailUnscanDocument,
    selectedCustomer,
    selectedCustomerProduct,
    selectedTypePeriode,
    selectedPeriodeDelivery,
  } = props;

  const {
    data: detailUnscanDataTable,
    isFetching: isFetchingDetailUnscanDataTable,
  } = useQuery(
    [
      'dataUnscanDocument',
      currentPage,
      currentSize,
      selectedCustomerProduct,
      selectedTypePeriode,
      selectedPeriodeDelivery,
    ],
    getListUnscanDocument,
    {
      enabled:
        selectedCustomer !== null &&
        selectedCustomerProduct !== null &&
        selectedTypePeriode !== null &&
        selectedPeriodeDelivery !== null &&
        modalDetailUnscanDocument === true,
    }
  );
  const handleCancel = () => {
    setModalDetailUnscanDocument(false);
  };
  const columnData = [
    {
      title: <b>Waybill</b>,
      dataIndex: 'waybill',
      key: 'waybill',
      width: 80,
    },
    {
      title: <b>Barcode</b>,
      dataIndex: 'barcode',
      key: 'barcode',
      width: 30,
    },
    {
      title: <b>Tanggal Cycle</b>,
      dataIndex: 'cycle',
      key: 'cycle',
      width: 30,
    },
    {
      title: <b>Produk</b>,
      dataIndex: 'customerProduct',
      key: 'customerProduct',
      width: 30,
    },
  ];

  return (
    <ModalDetailUnscanDocumentComponent
      modalDetailUnscanDocument={modalDetailUnscanDocument}
      handleCancel={handleCancel}
      detailUnscanDataTable={detailUnscanDataTable?.data || []}
      isFetchingDetailUnscanDataTable={isFetchingDetailUnscanDataTable}
      currentPage={currentPage}
      totalDatas={detailUnscanDataTable?.totalDatas || 0}
      totalPages={detailUnscanDataTable?.totalPages || 0}
      setCurrentPage={setCurrentPage}
      columnData={columnData}
    />
  );
}
