import React, { useEffect, useState } from 'react';

import AssigningCourierComponent from 'module/AssigningCourier/Component/AssigningCourierComponent';
import { store } from 'core/store/ConfigStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getListBranch } from 'services/BranchServices';
import { getProfileBranchRole } from 'services/AccountServices';
import { getCourierBranch } from 'services/CourierService';
import {
  mutateHeadBranchScanBarcode,
  mutateHeadBranchScanWaybill,
} from 'services/DeliveryService';
import ModalWaybillOptionContainer from './ModalWaybillOptionContainer';
import { Modal } from 'antd';

export default function AssigningCourierContainer() {
  const username = store.getState().Auth.UsernameAuth;
  const [modalWaybillOption, setModalWaybillOption] = useState<boolean>(false);
  const [dataWaybillOption, setDataWaybillOption] = useState<any>(null);
  const [selectedWaybill, setSelectedWaybill] = useState<string | null>(null);
  const [selectedCourierCode, setSelectedCourierCode] = useState<string | null>(
    null
  );
  const [showResponseStatus, setShowResponseStatus] = React.useState(false);
  const [responseType, setResponseType] = React.useState<
    'success' | 'error' | 'notFound' | undefined
  >(undefined);
  const [scanPayload, setScanPayload] = useState<any>(null);
  const formRef: any = React.useRef();

  const { data: dataBranch } = useQuery(['getDataBranch'], getListBranch);

  const { data: dataProfileBranchRole } = useQuery(
    ['getDataProfileBranchRole', username],
    getProfileBranchRole
  );

  const { data: dataCourierBranch } = useQuery(
    ['getDataCourierBranch', dataProfileBranchRole?.branch],
    getCourierBranch,
    { enabled: !!dataProfileBranchRole?.branch }
  );

  useEffect(() => {
    if (dataProfileBranchRole) {
      formRef.current.form.change('branchId', dataProfileBranchRole.branch);
    }
  }, [dataProfileBranchRole]);

  const mutateScanWaybill = useMutation(mutateHeadBranchScanWaybill, {
    onSuccess(data, variables) {
      const { courierId, waybillOrBarcode } = variables;
      if (data.status === 200) {
        setShowResponseStatus(true);
        setResponseType('success');
        setSelectedCourierCode(
          dataCourierBranch.find((item: any) => item.id === courierId).code
        );
        setSelectedWaybill(waybillOrBarcode);
        setModalWaybillOption(false);
        setDataWaybillOption(null);
      }
      formRef.current.form.resetFieldState('waybillOrBarcode');
      formRef.current.form.change('waybillOrBarcode', '');
    },
    onError(error: any, variables) {
      const { waybillOrBarcode } = variables;
      setSelectedWaybill(waybillOrBarcode);
      if (error.response.data.Message === 'No Waybill found!') {
        setShowResponseStatus(true);
        setResponseType('notFound');
      } else if (
        error.response.data.Message ===
          `Can not assign with No Waybill because status Success` ||
        error.response.data.Message ===
          `Can not assign with No Waybill because status Return` ||
        error.response.data.Message ===
          `Can not assign with No Waybill because status OnHold`
      ) {
        setShowResponseStatus(true);
        setResponseType('error');
      } else if (
        error.response.data.Message === 'Tanggal pengiriman tidak boleh kosong'
      ) {
        Modal.error({
          title: <b style={{ color: '#EA1A1A' }}>Gagal menjalankan proses</b>,
          content: <p>{error.response.data?.Message}</p>,
        });
      }
    },
  });

  const mutateScanBarcode = useMutation(mutateHeadBranchScanBarcode, {
    onSuccess(data, variables) {
      const { courierId, waybillOrBarcode } = variables;
      if (data.status === 200) {
        if (data.data.isMultiple) {
          setModalWaybillOption(true);
          setScanPayload(variables);
          setDataWaybillOption(data.data.data);
        } else {
          setShowResponseStatus(true);
          setResponseType('success');
          setSelectedCourierCode(
            dataCourierBranch.find((item: any) => item.id === courierId).code
          );
          setSelectedWaybill(waybillOrBarcode);
        }
      }
      formRef.current.form.resetFieldState('waybillOrBarcode');
      formRef.current.form.change('waybillOrBarcode', '');
    },
    onError(error: any, variables) {
      const { waybillOrBarcode } = variables;
      setSelectedWaybill(waybillOrBarcode);

      if (error.response.data.Message === 'No Barcode found!') {
        setShowResponseStatus(true);
        setResponseType('notFound');
      } else if (
        error.response.data.Message ===
          `Can not assign with No Barcode because status Success` ||
        error.response.data.Message ===
          `Can not assign with No Barcode because status Return` ||
        error.response.data.Message ===
          `Can not assign with No Barcode because status OnHold`
      ) {
        setShowResponseStatus(true);
        setResponseType('error');
      } else if (
        error.response.data.Message === 'Tanggal pengiriman tidak boleh kosong'
      ) {
        Modal.error({
          title: <b style={{ color: '#EA1A1A' }}>Gagal menjalankan proses</b>,
          content: <p>{error.response.data?.Message}</p>,
        });
      }
    },
  });

  const handleSubmit = (values: any) => {
    setShowResponseStatus(false);
    setSelectedWaybill(null);
    setSelectedCourierCode(null);
    setScanPayload(null);
    setResponseType(undefined);

    if (values.typeScan === 'waybill') {
      mutateScanWaybill.mutate(values);
    } else if (values.typeScan === 'barcode') {
      mutateScanBarcode.mutate(values);
    }
  };

  const handleReset = () => {
    setShowResponseStatus(false);
    setSelectedWaybill(null);
    setSelectedCourierCode(null);
    setScanPayload(null);
    setResponseType(undefined);
    formRef.current.form.restart();
  };

  const listCourier = dataCourierBranch?.map((item: any) => ({
    id: item.id,
    name: `${item.firstName} (${item.code})`,
  }));

  return (
    <div>
      <AssigningCourierComponent
        defaultBranchId={dataProfileBranchRole?.branch || null}
        formRef={formRef}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        isLoading={mutateScanWaybill.isLoading || mutateScanBarcode.isLoading}
        listBranch={dataBranch || []}
        listCourier={listCourier}
        responseType={responseType}
        selectedCourierCode={selectedCourierCode}
        selectedWaybill={selectedWaybill}
        showResponseStatus={showResponseStatus}
      />
      {modalWaybillOption && (
        <ModalWaybillOptionContainer
          dataWaybillOption={dataWaybillOption}
          handleSubmit={handleSubmit}
          isLoadingMutation={mutateScanWaybill.isLoading}
          modalWaybillOption={modalWaybillOption}
          scanPayload={scanPayload}
          selectedWaybill={selectedWaybill}
          setDataWaybillOption={setDataWaybillOption}
          setModalWaybillOption={setModalWaybillOption}
          setSelectedWaybill={setSelectedWaybill}
        />
      )}
    </div>
  );
}
