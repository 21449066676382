// DEV CHANGES ON 4 DESEMBER 2024

import React, { useMemo } from 'react';
import styles from './FormManifestBrankas.module.css';
import { Row, Col, Checkbox } from 'antd';
import { Form, Field } from 'react-final-form';

import { SelectOptionsInterface } from 'interface/general';
import { InputText } from 'components/InputField';
import SelectField from 'components/SelectField';
import InputDatePicker from 'components/InputDatePicker';
import RadioField from 'components/RadioField';
import Button from 'components/Button';

import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import { DataManifestBrankasProps } from '../../Interface/manifest.interface';

type FormManifestBrankasProps = {
  dataCustomers: SelectOptionsInterface[];
  dataManifestRetur: SelectOptionsInterface[];
  formRef: any;
  handleSubmit: (value: DataManifestBrankasProps) => void;
  isEdit?: boolean;
  isLoadingForm: boolean;
  isLoadingProducts?: boolean;
  isShowModalAdd: boolean;
  listProducts?: any;
  loadingManifestRetur: boolean;
  onClickAddRetur: () => void;
  setIsShowModalAdd: () => void;
  setSelectedCustomerId?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
};

export default function FormManifestBrankas({
  dataCustomers,
  dataManifestRetur,
  formRef,
  handleSubmit,
  isEdit = false,
  isLoadingForm = false,
  isLoadingProducts,
  isShowModalAdd = false,
  listProducts,
  loadingManifestRetur = false,
  onClickAddRetur,
  setIsShowModalAdd,
  setSelectedCustomerId,
}: FormManifestBrankasProps) {
  const initialValues: DataManifestBrankasProps = useMemo(
    () => ({
      cycleDate: null,
      isMonthly: false,
      bundleCode: '',
      type: 'Return',
      maxBundle: 1,
      customer: null,
      customerProducts: [],
      manifestSafeId: null,
      periodType: 'Cycle',
    }),
    []
  );

  return (
    <ModalMaterial
      teksTitle={`${isEdit ? 'EDIT' : 'TAMBAH'} MANIFEST BRANKAS`}
      width="70%"
      visible={isShowModalAdd}
      onCancel={setIsShowModalAdd}
    >
      <SpinMaterial spinning={isLoadingForm} size={'large'}>
        <div style={{ marginTop: 24, padding: '0 24px' }}>
          <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validate={values => {
              const errors = {} as any;
              if (!values.customer) {
                errors.customer = 'Pelanggan wajib diisi';
              }
              if (
                !values.customerProducts ||
                values.customerProducts.length === 0
              ) {
                errors.customerProducts = 'Produk Pelanggan wajib diisi';
              }
              if (!values.cycleDate) {
                errors.cycleDate = 'Tanggal wajib diisi';
              }
              if (!values.bundleCode) {
                errors.bundleCode = 'Kode Bundel wajib diisi';
              }
              if (!values.manifestSafeId) {
                errors.manifestSafeId = 'Kode Brankas wajib diisi';
              }
              if (values.maxBundle && values.maxBundle < 1) {
                errors.maxBundle = 'Tidak boleh kurang dari 1';
              }
              return errors;
            }}
          >
            {({ values, handleSubmit, valid, form }) => {
              formRef.current = form;
              return (
                <div className={styles.wrapperFilter}>
                  <div className={styles.wrapperForm}>
                    <Field name="type">
                      {({ input }) => (
                        <RadioField
                          data={[
                            { value: 'Success', label: 'Sukses' },
                            { value: 'Return', label: 'Return' },
                          ]}
                          onChange={value => input.onChange(value)}
                          value={input.value}
                          disabled={isEdit}
                        />
                      )}
                    </Field>
                    <Row gutter={24}>
                      <Col md={12} className={styles.filterColumn}>
                        <Field name="customer">
                          {({ input, meta }) => (
                            <SelectField
                              validate
                              label="Pelanggan"
                              placeholder="Pilih Pelanggan"
                              data={dataCustomers}
                              value={input.value}
                              onChange={value => {
                                if (setSelectedCustomerId) {
                                  setSelectedCustomerId(value);
                                }
                                return input.onChange(value);
                              }}
                              onBlur={value => {
                                input.onBlur(value);
                              }}
                              validateStatus={
                                meta.error && meta.touched
                                  ? 'error'
                                  : 'validating'
                              }
                              errorMessage={
                                meta.error && meta.touched && meta.error
                              }
                              disabled={isEdit}
                            />
                          )}
                        </Field>
                        <Field name="customerProducts">
                          {({ input, meta }) => (
                            <SelectField
                              validate
                              label="Produk"
                              placeholder="Pilih Produk"
                              data={listProducts}
                              loading={isLoadingProducts}
                              value={input.value}
                              mode="multiple"
                              onChange={value => input.onChange(value)}
                              onBlur={value => {
                                input.onBlur(value);
                              }}
                              validateStatus={
                                meta.error && meta.touched
                                  ? 'error'
                                  : 'validating'
                              }
                              errorMessage={
                                meta.error && meta.touched && meta.error
                              }
                              disabled={!values.customer || isEdit}
                            />
                          )}
                        </Field>
                        <Field name="maxBundle">
                          {input => (
                            <InputText
                              label="Maksimal Dokumen per Bundel"
                              formStyle={{ margin: 0 }}
                              type="number"
                              {...input}
                              min={0}
                              maxlength={3}
                            />
                          )}
                        </Field>
                      </Col>
                      <Col md={12} className={styles.filterColumn}>
                        <Field name="cycleDate">
                          {({ input, meta }) => (
                            <div className={styles.wrapperDateField}>
                              <InputDatePicker
                                label="Tanggal Periode"
                                placeholder={`Pilih ${
                                  values.isMonthly ? 'Bulan' : 'Tanggal'
                                }`}
                                onOpenChange={input.onBlur}
                                onChange={value => input.onChange(value)}
                                value={input.value}
                                error={
                                  meta.touched &&
                                  meta.error !== undefined &&
                                  meta.error
                                }
                                formStyle={{ flex: 1 }}
                                mode={values.isMonthly ? 'month' : 'date'}
                                addonBefore={
                                  <Field name="periodType">
                                    {({ input }) => (
                                      <SelectField
                                        value={input.value}
                                        data={[
                                          { id: 'Cycle', name: 'Cycle' },
                                          { id: 'Upload', name: 'Upload' },
                                        ]}
                                        style={{ width: 100 }}
                                        onChange={value =>
                                          input.onChange(value)
                                        }
                                        disabled={isEdit}
                                      />
                                    )}
                                  </Field>
                                }
                                disabled={isEdit}
                              />
                              <Field name="isMonthly">
                                {({ input }) => (
                                  <Checkbox
                                    style={{
                                      marginBottom:
                                        meta.touched &&
                                        meta.error !== undefined &&
                                        meta.error
                                          ? 32
                                          : 15,
                                      width: '25%',
                                    }}
                                    checked={input.value}
                                    onChange={value => input.onChange(value)}
                                    disabled={isEdit}
                                  >
                                    Bulanan
                                  </Checkbox>
                                )}
                              </Field>
                            </div>
                          )}
                        </Field>

                        <Field name="bundleCode">
                          {input => (
                            <InputText
                              label="Kode Bundel"
                              placeholder="Masukan Kode bundel"
                              formStyle={{ margin: 0 }}
                              disabled
                              onChange={value => input.onChange(value)}
                              {...input}
                            />
                          )}
                        </Field>
                        <Field name="manifestSafeId">
                          {({ input, meta }) => (
                            <div className={styles.wrapperSelectField}>
                              <SelectField
                                label="Kode Brankas"
                                placeholder="Masukan kode brankas"
                                data={dataManifestRetur}
                                value={input.value || undefined}
                                onChange={value => input.onChange(value)}
                                onBlur={value => {
                                  input.onBlur(value);
                                }}
                                validate
                                validateStatus={
                                  meta.error && meta.touched
                                    ? 'error'
                                    : 'validating'
                                }
                                errorMessage={
                                  meta.error && meta.touched && meta.error
                                }
                                loading={loadingManifestRetur}
                              />
                              <p
                                onClick={onClickAddRetur}
                                className={styles.textAddKodeRetur}
                              >
                                Tambah Kode Baru
                              </p>
                            </div>
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </div>

                  <div className={styles.actionFilter}>
                    <Button
                      text={isEdit ? 'Simpan' : 'Simpan & Scan Manifest'}
                      onClick={handleSubmit}
                      disabled={!valid}
                    />
                  </div>
                </div>
              );
            }}
          </Form>
        </div>
      </SpinMaterial>
    </ModalMaterial>
  );
}
