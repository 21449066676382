import { useMutation, useQuery } from '@tanstack/react-query';
import { Modal } from 'antd';
import UploadSoftcopyComponent from 'components/Content/Delivery/Resend/UploadSoftcopy/UploadSoftcopyComponent';
import history from 'core/utils/History';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { getCustomerDetailCsvList } from 'services/CustomerService';
import { mutateUploadDataKU } from 'services/DeliveryService';
import {
  getAllCustomers,
  getProductListByCustomer,
} from 'services/GeneralService';

export default function UploadSoftcopyContainer() {
  const [selectedCustomerId, setSelectedCustomerId] = useState<
    number | undefined
  >(undefined);
  const formRef = React.useRef<any>(null);

  const { data: dataCustomers, isFetching: isFetchingCustomers } = useQuery({
    queryKey: ['GetListCustomer'],
    queryFn: getAllCustomers,
  });

  const { data: dataProducts, isFetching: isFetchingProducts } = useQuery({
    queryKey: ['getProductListByCustomerId', selectedCustomerId],
    queryFn: getProductListByCustomer,
    enabled: !!selectedCustomerId,
  });

  const { data: dataUploadTemplate, isFetching: isFetchingTemplate } = useQuery(
    {
      queryKey: ['getCustomerDetailCsvList', selectedCustomerId],
      queryFn: () =>
        getCustomerDetailCsvList({
          IdCustomer: selectedCustomerId,
          isActiveType: 'Active',
        }),
      enabled: !!selectedCustomerId,
    }
  );

  const mutateSubmitUploadDataKU = useMutation({
    mutationFn: mutateUploadDataKU,
    onSuccess(data) {
      if (data.status === 200) {
        Modal.success({
          title: <p style={{ color: '#31E116' }}>Berhasil Mengunggah</p>,
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>
                Klik OK untuk langkah selanjutnya
              </p>
            </div>
          ),
          onOk: () => {
            history.push('/delivery/document?deliveryDistribution');
          },
        });
      }
    },
    onError(error: any) {
      const { title, Message } = error?.response?.data;
      console.log('error', error.response.data);
      Modal.error({
        title: (
          <p className={'titleModalNotifFailed'}>
            Gagal menjalankan proses !!!
          </p>
        ),
        content: (
          <div>
            <p className={'titleModalNotifContent'}>{Message || title}</p>
          </div>
        ),
        onOk() {},
      });
    },
  });

  const listCustomer =
    dataCustomers?.data?.data?.map((item: any) => ({
      id: item.id,
      name: item.name,
    })) || [];
  const listProducts =
    dataProducts?.map((item: any) => ({
      id: item.id,
      name: item.name,
      productType: item.productType,
    })) || [];
  const listTemplateUpload =
    dataUploadTemplate?.data?.map((item: any) => ({
      id: item.id,
      name: item.nameCustomCsv,
    })) || [];
  const handleReset = () => {
    formRef.current?.reset();
  };
  const handleSubmit = values => {
    const {
      newCustomerProductId,
      oldCustomerProductId,
      customerCsvId,
      fileName,
      fileCsv,
      deliveryTime,
      processTime,
      isChangeProduct,
    } = values;
    const payload = {
      oldCustomerProductId,
      customerProductId: !isChangeProduct
        ? oldCustomerProductId
        : newCustomerProductId,
      customerCsvId,
      fileName,
      fileCsv,
      deliveryTime:
        deliveryTime && moment(deliveryTime).format('YYYY-MM-DDTHH:mm:ss.SSSz'),
      processTime:
        processTime && moment(processTime).format('YYYY-MM-DDTHH:mm:ss.SSSz'),
    };
    mutateSubmitUploadDataKU.mutate(payload);
  };
  return (
    <UploadSoftcopyComponent
      formRef={formRef}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      isFetchingCustomers={isFetchingCustomers}
      isFetchingProducts={isFetchingProducts}
      isFetchingTemplate={isFetchingTemplate}
      isLoadingMutation={mutateSubmitUploadDataKU.isLoading}
      listCustomer={listCustomer}
      listProducts={listProducts}
      listTemplateUpload={listTemplateUpload}
      setSelectedCustomerId={setSelectedCustomerId}
    />
  );
}
