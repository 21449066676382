// DEV CHANGES ON 4 DESEMBER 2024

import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { SelectOptionsInterface } from 'interface/general';
import {
  getManifestBrankas,
  updateManifestBrankas,
} from 'services/ManifestSaveBrankas';
import FormManifestBrankas from '../components/FormManifestBrankas';
import ModalStatus from 'materials/ModalStatus';
import moment from 'moment-timezone';
import {
  DataManifestBrankasProps,
  DetailManifestBrankasProps,
} from '../Interface/manifest.interface';
import { getProductByCustomers } from 'services/GeneralService';

type ModalEditManifestBrankasProps = {
  isShowModalEdit: number | null;
  setIsShowModalEdit: () => void;
  dataManifestRetur: SelectOptionsInterface[];
  dataCustomers: SelectOptionsInterface[];
  loadingManifestRetur: boolean;
  onClickAddRetur: () => void;
  refetchTable: () => void;
};

export default function ModalEditManifestBrankas({
  isShowModalEdit,
  setIsShowModalEdit,
  dataManifestRetur,
  loadingManifestRetur,
  onClickAddRetur,
  dataCustomers,
  refetchTable,
}: ModalEditManifestBrankasProps) {
  const [selectedCustomerId, setSelectedCustomerId] = useState<
    number | undefined
  >(undefined);
  const formRef: any = React.useRef();

  const { data: dataProducts, isFetching: isLoadingProducts } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataProductsForm', { id: selectedCustomerId }],
    queryFn: ({ queryKey }) => getProductByCustomers(queryKey),
    enabled: !!selectedCustomerId,
    refetchOnWindowFocus: false,
  });

  const { mutate: submitManifestBrankas, isLoading: isLoadingSubmitForm } =
    useMutation(updateManifestBrankas, {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Data berhasil diupdate',
            handleOke: () => {},
          });
          setIsShowModalEdit();
          refetchTable();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const { isFetching: isLoadingDetail, data: dataManifest } = useQuery<
    AxiosResponse<DetailManifestBrankasProps>
  >(
    ['DataDetailManifest', isShowModalEdit],
    () => getManifestBrankas(isShowModalEdit),
    {
      onSuccess(data) {
        const response = data.data;
        setSelectedCustomerId(response.customerId);
        formRef.current.initialize({
          cycleDate: moment(response.cycleDate),
          isMonthly: response.isMonthly,
          bundleCode: response.bundleCode,
          type: response.brankasType,
          maxBundle: response.maxBundle,
          customer: response.customerId,
          customerProducts: response.customerProductIds,
          manifestSafeId: response.manifestSafeCodeId,
          periodType: response.periodType,
        });
      },
      enabled: !!isShowModalEdit,
      refetchOnWindowFocus: false,
    }
  );

  const handleSubmit = (value: DataManifestBrankasProps) => {
    submitManifestBrankas({
      id: dataManifest?.data.id,
      maxBundle: value.maxBundle,
      manifestSafeId: value.manifestSafeId,
    });
  };

  const listProducts =
    (dataProducts &&
      dataProducts?.data?.data?.map((item: any) => ({
        id: item.id,
        name: item.name,
      }))) ||
    [];

  return (
    <FormManifestBrankas
      dataCustomers={dataCustomers}
      dataManifestRetur={dataManifestRetur}
      formRef={formRef}
      handleSubmit={handleSubmit}
      isEdit
      isLoadingForm={isLoadingSubmitForm || isLoadingDetail}
      isLoadingProducts={isLoadingProducts}
      isShowModalAdd={!!isShowModalEdit}
      listProducts={listProducts}
      loadingManifestRetur={loadingManifestRetur}
      onClickAddRetur={onClickAddRetur}
      setIsShowModalAdd={setIsShowModalEdit}
    />
  );
}
